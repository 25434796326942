import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  faCircleCheck,
  faCircleXmark,
  faMagnifyingGlass,
  faPencil,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { constant } from '@assets/constant';
import { TranslocoService } from '@ngneat/transloco';
import { NotificaService } from '@services/notifica.service';
import * as moment from 'moment';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-notifiche',
  templateUrl: './notifiche.component.html',
  styleUrls: ['./notifiche.component.scss'],
})
export class NotificheComponent implements OnInit {
  constructor(
    private transloco: TranslocoService,
    private notifica: NotificaService
  ) {}
  ngOnInit(): void {
    this.transloco.selectTranslation().subscribe({
      next: (translation: any) => {
        this.allTranslation = translation;
      },
    });
    this.paginateNotifications();
  }

  isPhone: any;
  public faMagnifyingGlass = faMagnifyingGlass;
  public faPencil = faPencil;
  public faTrashCan = faTrashCan;
  public faCirclePlus = faCirclePlus;
  public faCalendarDays = faCalendarDays;
  failToast: boolean = false;
  public faCircleCheck = faCircleCheck;
  public faCircleXmark = faCircleXmark;
  notificaErr: boolean = false;
  messErr: any;
  search: string = '';
  title: string = '';
  description: string = '';
  link: string = '';
  destination: number = 0;
  showModalEdit = false;
  page: number = 1;
  date: any = null;
  columns: any[] = [
    { display: 'DATA', dataIndex: 'data' },
    { display: 'ORA', dataIndex: 'ora' },
    { display: 'MESSAGGIO NOTIFICA', dataIndex: 'messaggio' },
  ];
  notifiche: any[] = [];
  notifications: any[] = [];
  allTranslation: any = false;
  showDropdown = false;
  totalPages: number = 27;
  linkEdit: string = '';
  descriptionEdit: string = '';
  titleEdit: string = '';
  destinationEdit: number = 0;
  messageToEdit: any = {};
  test = false;
  @ViewChild('checkbox1') checkbox1: any;
  @ViewChild('checkbox2') checkbox2: any;
  @ViewChild('checkbox3') checkbox3: any;
  @ViewChild('checkbox1Edit') checkbox1Edit: any;
  @ViewChild('checkbox2Edit') checkbox2Edit: any;
  @ViewChild('checkbox3Edit') checkbox3Edit: any;

  updateDestination(
    checkboxNumber: number,
    event: Event,
    edit?: boolean
  ): void {
    if (edit) {
      const target = event.target as HTMLInputElement;
      this.checkbox1Edit.checked = false;
      this.checkbox2Edit.checked = false;
      this.checkbox3Edit.checked = false;
      if (target.checked) {
        this.destinationEdit = checkboxNumber;
        if (checkboxNumber === 1) {
          this.checkbox1Edit.checked = true;
          this.notificaErr = false;
        } else if (checkboxNumber === 2) {
          this.checkbox2Edit.checked = true;
          this.notificaErr = false;
        } else if (checkboxNumber === 3) {
          this.checkbox3Edit.checked = true;
          this.notificaErr = false;
        }
      } else {
        if (this.destinationEdit === checkboxNumber) {
          this.destinationEdit = 0;
          if (checkboxNumber === 1) {
            this.checkbox1Edit.checked = false;
          } else if (checkboxNumber === 2) {
            this.checkbox2Edit.checked = false;
          } else if (checkboxNumber === 3) {
            this.checkbox3Edit.checked = false;
          }
        }
      }
    } else {
      const target = event.target as HTMLInputElement;
      if (target.checked) {
        this.destination = checkboxNumber;
        if (checkboxNumber === 1) {
          this.checkbox1.checked = true;
          this.notificaErr = false;
        } else if (checkboxNumber === 2) {
          this.checkbox2.checked = true;
          this.notificaErr = false;
        } else if (checkboxNumber === 3) {
          this.checkbox3.checked = true;
          this.notificaErr = false;
        }
      } else {
        if (this.destination === checkboxNumber) {
          this.destination = 0;
          if (checkboxNumber === 1) {
            this.checkbox1.checked = false;
          } else if (checkboxNumber === 2) {
            this.checkbox2.checked = false;
          } else if (checkboxNumber === 3) {
            this.checkbox3.checked = false;
          }
        }
      }
    }
  }

  createNotification() {
    if (this.destination === 0) {
      this.messErr = 'definire almeno un destinatario';
      this.notificaErr = true;
      this.failToast = true;
      setTimeout(() => {
        this.failToast = false;
      }, 3000);
      return;
    }
    return this.notifica
      .createNotification(
        this.title,
        this.description,
        this.link,
        this.destination
      )
      .subscribe(
        (response: any) => {
          this.title = '';
          this.description = '';
          this.link = '';
          this.destination = 0;
          this.paginateNotifications();
          this.resetCheckboxes();
        },
        (error: any) => {
          this.messErr = error.error.message;

          this.failToast = true;
          setTimeout(() => {
            this.failToast = false;
          }, 3000);
        }
      );
  }

  editNotification() {
    if (this.destinationEdit === 0) {
      this.messErr = 'definire almeno un destinatario';
      this.notificaErr = true;
      this.failToast = true;
      setTimeout(() => {
        this.failToast = false;
      }, 3000);
      return;
    }
    return this.notifica
      .editNotification(
        this.titleEdit,
        this.descriptionEdit,
        this.linkEdit,
        this.destinationEdit,
        this.messageToEdit.id
      )
      .subscribe(
        (response: any) => {
          this.titleEdit = '';
          this.descriptionEdit = '';
          this.linkEdit = '';
          this.destinationEdit = 0;
          this.showModalEdit = false;
          this.paginateNotifications();
          this.resetCheckboxes();
        },
        (error: any) => {
          this.messErr = error.error.message;

          this.failToast = true;
          setTimeout(() => {
            this.failToast = false;
          }, 3000);
        }
      );
  }

  deleteNotification() {
    this.notifica.deleteNotification(this.messageToEdit.id).subscribe(
      (response: any) => {
        this.titleEdit = '';
        this.descriptionEdit = '';
        this.linkEdit = '';
        this.destinationEdit = 0;
        this.showModalEdit = false;
        this.paginateNotifications();
        this.resetCheckboxes();
      },
      (error: any) => {
        this.messErr = error.error.message;

        this.failToast = true;
        setTimeout(() => {
          this.failToast = false;
        }, 3000);
      }
    );
  }

  resetCheckboxes() {
    this.checkbox1.checked = false;
    this.checkbox2.checked = false;
    this.checkbox3.checked = false;
  }

  visibile(event: MouseEvent) {
    this.showDropdown = !this.showDropdown;
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (this.showDropdown) {
      this.showDropdown = false;
    }
  }

  paginateNotifications() {
    return this.notifica
      .paginateNotification(this.search, this.date, this.page - 1)
      .subscribe(
        (response: any) => {
          this.notifiche = response.data;
          this.totalPages = response.totalPages;
          this.notifications = this.notifiche.map((notifiche: any) => ({
            id: notifiche.id,
            destinatari: notifiche.destinatari,
            descrizione: notifiche.descrizione,
            link: notifiche.link,
            titolo: notifiche.titolo,
            Data: notifiche.createdAt.slice(0, 10),
            data: moment(notifiche.createdAt).format('DD/MM/YYYY'),
            ora: notifiche.createdAt.slice(11, 16),
            messaggio: notifiche.titolo + ':\n' + notifiche.descrizione,
          }));
        },
        (error: any) => {
          console.error(
            'Errore durante il recupero delle informazioni delle notifiche',
            error
          );
        }
      );
  }

  goNext(page: number) {
    page < this.totalPages ? (this.page = page + 1) : '';
    this.paginateNotifications();
  }

  showModalTable(event: any) {
    this.showModalEdit = true;
    this.messageToEdit = event;
    this.titleEdit = event.titolo;
    this.descriptionEdit = event.descrizione;
    this.linkEdit = event.link;
    this.destinationEdit = event.destinatari;

    setTimeout(() => {
      let check = document.getElementById('check' + this.destinationEdit);
      if (check) {
        let checkInput = check.querySelector('input');
        if (checkInput) {
          checkInput.click();
        }
      }
    }, 0);
  }

  goBack(page: number) {
    page === 1 ? '' : (this.page = page - 1);
    this.paginateNotifications();
  }

  getPage(page: number) {
    this.page = page;
    this.paginateNotifications();
  }

  handleDateSelection(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target instanceof HTMLInputElement && target.value) {
      const selectedDate = target.value;
    } else {
    }
  }

  applyFilter() {
    this.paginateNotifications();
  }
}
