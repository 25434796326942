import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faMagnifyingGlass, faPencil } from '@fortawesome/free-solid-svg-icons';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { faXmark, faUpload } from '@fortawesome/free-solid-svg-icons';
import { HostListener } from '@angular/core';
import { BanchedatiService } from '@services/banchedati.service';
import * as moment from 'moment';
import { BancadatiComponent } from '../bancadati/bancadati.component';
import { ImageServiceService } from '@services/image-service.service';
import { BehaviorSubject, Subscription, forkJoin, map, take } from 'rxjs';
import { get, max, set } from 'lodash';
import { SelectionService } from '@services/selection.service';
import { MappingService } from '@services/mapping.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormService } from '@services/form.service';
import { ModaleImportService } from '@services/modale-import.service';
import { FiltriBancheService } from '@services/filtri-banche.service';
import { StatoUtenteService } from '@services/stato-utente.service';
import { MessageService } from '@services/message.service';
import { LoaderComponent } from 'src/app/components/loader/loader.component';

@Component({
  selector: 'app-banche-dati',
  templateUrl: './banche-dati.component.html',
  styleUrls: ['./banche-dati.component.scss'],
})
export class BancheDatiComponent implements OnInit {
  constructor(
    private mappingService: MappingService,
    private transloco: TranslocoService,
    private banchedati: BanchedatiService,
    private router: Router,
    private imageService: ImageServiceService,
    private selectionService: SelectionService,
    private formService: FormService,
    private importService: ModaleImportService,
    private filtri: FiltriBancheService,
    private statoUtente: StatoUtenteService,
    public statoUtenteService: StatoUtenteService,
    private message: MessageService
  ) {}

  getEnte() {
    this.subscription.add(
      this.mappingService.getListaEnti().subscribe((enti) => {
        this.listaEnti = enti;
      })
    );
    this.subscription.add(
      this.mappingService.getNomiEnti().subscribe((nomi) => {
        this.nomiEnti = nomi;
        this.combinedEnti = [...this.nomiEnti];
      })
    );
  }

  getProfili() {
    this.subscription.add(
      this.mappingService.getListaProfili().subscribe((profili) => {
        this.listaProfili = profili;
      })
    );
    this.subscription.add(
      this.mappingService.getNomiProfili().subscribe((nomi) => {
        this.nomiProfili = nomi;
        this.combinedProfili = [...this.nomiProfili];
      })
    );
  }

  getRegioni() {
    this.subscription.add(
      this.mappingService.getListaRegioni().subscribe((regioni) => {
        this.listaRegioni = regioni;
      })
    );
  }

  getLib() {
    this.subscription.add(
      this.mappingService.getLibrerie().subscribe((lib) => {
        this.librerie = lib;
      })
    );
  }

  combinedAnno: any[] = [];

  getAnno() {
    this.subscription.add(
      this.mappingService.getListaAnni().subscribe((anni) => {
        this.Anno = anni;
        this.combinedAnno = this.Anno.map((anno) => anno.nome);
      })
    );
  }

  avvia() {
    this.getLib();
    this.getEnte();
    this.getProfili();
    this.getRegioni();
    this.getAnno();
    this.paginateBancheDati();
  }

  erroreForm: string = '';

  filtriBanche() {
    this.subscription.add(
      this.filtri.getAnni().subscribe((anni) => {
        this.selectedAnni = anni;
      })
    );
    this.subscription.add(
      this.filtri.getEnti().subscribe((enti) => {
        this.selectedEnti = enti;
      })
    );
    this.subscription.add(
      this.filtri.getProfili().subscribe((profili) => {
        this.selectedProfili = profili;
      })
    );
    this.subscription.add(
      this.filtri.getRegioni().subscribe((regioni) => {
        this.selectedRegioni = regioni;
      })
    );
  }
  ngOnInit(): void {
    this.avvia();
    this.filtriBanche();
    this.sub();
  }

  sub() {
    this.subscription.add(
      this.importService.getErroreImport().subscribe((errore) => {
        this.erroreImport = errore;
      })
    );
    this.subscription.add(
      this.importService.getSelectedFile().subscribe((file) => {
        this.selectedFile = file;
      })
    );

    this.subscription.add(
      this.importService.getExistImport().subscribe((value) => {
        this.existImport = value;
      })
    );
    this.subscription.add(
      this.formService.getExistPass().subscribe((value) => {
        this.existPass = value;
      })
    );

    this.subscription.add(
      this.statoUtente.getIsLoggin().subscribe((res) => {
        this.isLoggin = res;
      })
    );
    this.subscription.add(
      this.statoUtenteService.getRoleId().subscribe((roleId) => {
        this.isAdmin = roleId === 3 ? true : false;
      })
    );
    this.subscription.add(
      this.transloco.selectTranslation().subscribe({
        next: (translation: any) => {
          this.allTranslation = translation;
        },
      })
    );
    this.subscription.add(
      this.imageService.getCroppedImage().subscribe((image) => {
        if (image instanceof File) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
          };
          reader.readAsDataURL(image);
        } else {
          this.imageUrl = '';
        }
        this.selectedFile = image;
      })
    );
    this.subscription.add(
      this.imageService.getMostra().subscribe((mostra) => {
        this.showModal = mostra;
      })
    );

    this.subscription.add(
      this.formService.getShowPopUp().subscribe((value) => {
        this.showPop = value;
      })
    );
    this.subscription.add(
      this.importService.getShowPopImport().subscribe((value) => {
        this.showPopImport = value;
      })
    );
    this.subscription.add(
      this.importService.getImport().subscribe((value) => {
        this.import = value;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.imageService.setCroppedImage(null);
    this.selectionService.clearSelection();
    this.formService.setFormError(false);
    this.mappingService.setEnteID(0);
  }

  @ViewChild('selectElement') selectElement: any;

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;

  selectedEnti: number[] = [];
  selectedEntiFiltro: any[] = [];
  showInput: boolean = false;
  isPhone: any;
  existPass: boolean = false;
  existImport: boolean = false;
  public faMagnifyingGlass = faMagnifyingGlass;
  public faPencil = faPencil;
  public faTrashCan = faTrashCan;
  public faFilter = faFilter;
  public faCircleXmark = faCircleXmark;
  public faTrash = faTrash;
  public faCopy = faCopy;
  public faNewspaper = faNewspaper;
  public faSquarePlus = faSquarePlus;
  public faRotateLeft = faRotateLeft;
  public faRotateRight = faRotateRight;
  public faXmark = faXmark;
  public faUpload = faUpload;
  public faStar = faStar;
  roleId: number = 0;
  icona = faFilter;
  isAdmin: boolean = this.roleId === 3 ? true : false;
  import: boolean = false;
  primaTabella: boolean = true;
  secondaTabella: boolean = false;
  search: string = '';
  allTranslation: any = false;
  // ngmodelcard
  titolo: string = '';
  descrizione: string = '';
  ente: string = '';
  materia: string = '';
  profilo: string = '';
  titoloPop: string = '';
  nomeModifica: string = '';
  immagine: string = '';
  // ngmodelmodal
  titoloModal: string = '';
  descrizioneModal: string = '';
  enteModal: string = '';
  materiaModal: string = '';
  profiloModal: string = '';
  showModal: boolean = false;
  selectedYears: string[] = [];
  Anno: any[] = [];
  check: number[] = [];
  showDropdown: boolean = false;
  showSlide: boolean = false;
  selectValue: string[] = [];
  selectedRegioni: number[] = [];
  showPop: boolean = false;
  totalPages: number = 27;
  page: number = 1;
  idToNavigate!: number;
  regione: string = '';
  idArgomento: number = 0;
  idBancaDati: number = 0;
  difficolta: number = 0;
  enti: any[] = [];
  listaEnti: any[] = [];
  idEnti: number[] = [];
  initialSelectedEnti: number[] = [];
  materie: any[] = [];
  listaMaterie: any[] = [];
  profili: any[] = [];
  listaProfili: any[] = [];
  initialStateSelectedProfili: number[] = [];
  selectedProfiliFiltro: number[] = [];
  idToModify!: number;
  regioni: any[] = [];
  listaRegioni: any[] = [];
  changeId: number[] = [];
  nomiRegioni: any;
  private regioniSubject = new BehaviorSubject<any[]>([]);
  public regioni$ = this.regioniSubject.asObservable();
  initialStateSelectedRegioni: number[] = [];
  selectedRegioniFiltro: number[] = [];
  selectedRegione: any;
  selectedProfilo: any;
  selectedLibreria: any;
  selectedEnte: any;
  selectedAnno: any;
  bancadatiIds: string[] = [];
  combinedEnti!: string[];
  combinedProfili!: string[];

  listaBancheDati: any[] = [];
  cercaNome: string = '';
  nomeCerca: string = '';
  descrizioneCerca: string = '';
  selectedProfili: number[] = [];
  nomiAnno: any;
  nomiProfili: any;
  nomiEnti: any;
  public fileUrl: any | null = null;
  public imgUrl: any | null = null;
  selectedFile: any = null;
  selectedFileImg: any = null;
  initialStateSelectedAnno: string[] = [];
  private subscription: Subscription = new Subscription();
  public imageUrl: any;
  idToDelete: number | null = null;
  duplicateID: number[] = [];
  idToExport: number[] = [];
  exportBancheda: any[] = [];
  exportListaBancheDati: any[] = [];
  materiaModifica: string = '';
  profiloModifica: string = '';
  annoModifica: string = '';
  descrizioneModifica: string = '';
  enteModifica: string = '';
  selectedAnni: string[] = [];
  selectedAnniFiltro: string[] = [];
  selectedNomiEntiFiltro: string[] = [];
  selectedNomiRegioniFiltro: string[] = [];
  elimina: boolean = false;
  larghezza = { width: '100%' };

  importLibreria: boolean = false;
  importNormale: boolean = true;
  importDaLibreria: boolean = false;

  loader: boolean = false;
  isScrollable: boolean = false;
  librerie: any[] = [];
  messErr: string = '';
  erroreImport: string = '';

  isLoggin: boolean = false;
  showPopImport: boolean = false;

  columns: any[] = [
    { display: 'Numero domande', dataIndex: 'numero' },
    { display: 'Icona', dataIndex: 'cover' },
    { display: 'nome', dataIndex: 'nome' },
    { display: 'ente', dataIndex: 'ente' },
    { display: 'profilo', dataIndex: 'profilo' },
    { display: 'località', dataIndex: 'regione' },

    { display: 'descrizione', dataIndex: 'descrizione' },
    { display: 'anno', dataIndex: 'anno' },
  ];

  showLib() {
    this.importLibreria = !this.importLibreria;
    this.importDaLibreria = true;
    this.importNormale = false;
  }

  showDelete(event: MouseEvent) {
    this.elimina = !this.elimina;
    event.stopPropagation();
  }

  removeImage() {
    this.imageService.setCroppedImage(null);
  }

  applyFilter() {
    this.paginateBancheDati();
  }

  isChecked(id: number): boolean {
    return this.check.includes(id);
  }

  updateSelectionAll() {
    // Svuota gli array all'inizio del metodo
    this.check = [];
    this.bancadatiIds = [];
    this.duplicateID = [];
    this.idToExport = [];
    this.idToDelete = null;

    const selectedIds = this.selectionService.getSelectedIds();

    if (selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        this.check.push(id);
        this.idToModify = id;
        this.bancadatiIds.push(id.toString());
        this.duplicateID.push(id);
        this.idToExport.push(id);
        this.idToDelete =
          this.check.length > 0 ? this.check[this.check.length - 1] : null;
      });
    } else {
      this.selectionService.deselectAll();
      this.showSlide = false;
    }

    return selectedIds;
  }

  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }

  updateSelection() {
    const ids = [...this.selectionService.getSelectedIds()];

    let checkSet = new Set(this.check);
    let bancadatiIdsSet = new Set(this.bancadatiIds);
    let duplicateIDSet = new Set(this.duplicateID);
    let idToExportSet = new Set(this.idToExport);

    ids.forEach((id) => {
      if (id !== 0) {
        checkSet.add(id);
        this.idToModify = id;
        bancadatiIdsSet.add(id.toString());
        duplicateIDSet.add(id);
        idToExportSet.add(id);
        this.idToDelete =
          this.check.length > 0 ? this.check[this.check.length - 1] : null;
      }
    });

    this.check.forEach((id) => {
      if (!ids.includes(id)) {
        checkSet.delete(id);
        bancadatiIdsSet.delete(id.toString());
        duplicateIDSet.delete(id);
        idToExportSet.delete(id);
      }
    });

    this.check = Array.from(checkSet);
    this.bancadatiIds = Array.from(bancadatiIdsSet);
    this.duplicateID = Array.from(duplicateIDSet);
    this.idToExport = Array.from(idToExportSet);
    this.idToDelete =
      this.check.length > 0 ? this.check[this.check.length - 1] : null;

    if (this.check.length === 0) {
      this.showSlide = false;
    }

    return this.selectionService.getSelectedIds();
  }

  pippo() {
    return this.check.length > 0 ? this.check.length : 0;
  }

  visibile(event: MouseEvent) {
    this.showDropdown = !this.showDropdown;

    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (this.showDropdown) {
      this.showDropdown = false;
    }
    if (this.showPop) {
      this.showPop = false;
      this.selectionService.clearSelection();
      this.selectionService.updateCheck(false);
      this.check = [];
      this.paginateBancheDati();
    }
    if (this.showPopImport) {
      this.showPopImport = false;
      this.paginateBancheDati();
    }
    if (this.import) {
      this.import = false;
      this.importLibreria = false;
      this.titoloModal = '';
      this.descrizioneModal = '';
      this.selectedEnte = null;
      this.selectedAnno = null;
      this.selectedProfilo = null;
      this.selectedRegione = null;
      this.selectedLibreria = null;
    }
    if (this.showModal === true) {
      this.showModal = false;
      this.imageService.setMostra(false);
    }
    if (this.elimina === true) {
      this.elimina = false;
    }
  }

  vedi() {
    this.showSlide = !this.showSlide;
  }

  reset() {
    this.filtri.setAnni([]);
    this.filtri.setEnti([]);
    this.filtri.setProfili([]);
    this.filtri.setRegioni([]);

    this.paginateBancheDati();
  }

  chiudi() {
    if (this.showDropdown == true) {
      this.showDropdown = false;
    }
  }

  chiudiPop() {
    this.showPop = false;
    this.selectionService.clearSelection();
    this.selectionService.updateCheck(false);
    this.check = [];
    this.paginateBancheDati();
  }

  chiudiPopImport() {
    this.showPopImport = false;

    this.paginateBancheDati();
  }

  goNext(page: number) {
    page < this.totalPages ? (this.page = page + 1) : '';
    this.paginateBancheDati();
  }

  goBack(page: number) {
    page === 1 ? '' : (this.page = page - 1);
    this.paginateBancheDati();
  }

  getPage(page: number) {
    this.page = page;
    this.paginateBancheDati();
  }

  importaBanca(event: MouseEvent) {
    event.stopPropagation();
    this.importService.setImport(true);
  }

  goToBanca(id: number, nome: string) {
    this.banchedati.setIdToNavigate(id);
    encodeURI(nome);
    this.router.navigate(['/bancadati'], {
      queryParams: {
        id: id,
        nome: nome,
      },
    });
  }

  modifica(id: number) {
    this.idToExport.push(id);
  }

  colonne: any[] = [
    'numero',
    'ente',
    'profilo',
    'regione',
    'nome',
    'descrizione',
    'anno',
  ];

  colonneDisplayNames: { [key: string]: string } = {
    numero: 'numero domande',
    ente: 'ente',
    profilo: 'profilo',
    regione: 'regione',
    nome: 'nome banca dati',
    descrizione: 'descrizione',
    anno: 'anno',
  };

  getArrayOrUndefined(array: any[]): any[] | undefined {
    return array.length > 0 ? array : undefined;
  }

  paginateBancheDati() {
    LoaderComponent.isLoading = true;
    this.perTeId = [];

    let empty = false;
    const emptyProf = this.selectedProfili.find((el) => el === -1)
      ? (empty = true)
      : (empty = false);
    return this.banchedati
      .paginateBancaDati(
        this.page - 1,
        this.nomeCerca,
        this.descrizioneCerca,
        this.getArrayOrUndefined(this.selectedAnni),
        this.getArrayOrUndefined(this.selectedRegioni),
        this.getArrayOrUndefined(this.selectedEnti),
        this.getArrayOrUndefined(this.selectedProfili),

        emptyProf
      )
      .subscribe(
        (response: any) => {
          const res = response.data;
          this.totalPages = response.totalPages;
          this.listaBancheDati = res.map((res: any) => {
            if (res.perTe) {
              this.perTeId.push(res.id);
            }

            return {
              id: res.id,
              numero: res.numeroDomande || '-',
              ente: res.bando?.enteProfilo?.ente?.nome || 'Non disponibile',
              regione: res.bando.regione?.nome || '-',
              nome: res.nome || '-',
              anno: res.anno || '-',
              profilo:
                res.bando?.enteProfilo?.profilo?.nome || 'Non disponibile',
              descrizione: res.bando.descrizione || '-',
              cover: res.bando.cover || '',
              perTe: res.perTe,
            };
          });

          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          LoaderComponent.isLoading = false;
          console.error(
            'Errore durante il recupero delle informazioni ',
            error
          );
        }
      );
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    const input = event.target as HTMLInputElement;
    if (input?.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        this.fileUrl = reader.result as string;
      };
      reader.readAsDataURL(input.files[0]);
    }

    this.titoloModal = this.selectedFile.name;
  }

  onButtonClick(event: MouseEvent) {
    event.stopPropagation();
    if (this.fileInput) {
      const inputElement: HTMLInputElement = this.fileInput.nativeElement;
      inputElement.click();
    }
    this.import = true;
    this.importDaLibreria = false;
    this.importNormale = true;
  }

  setImage() {
    this.subscription.add(
      this.imageService.getCroppedImage().subscribe((image) => {
        if (image instanceof File) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
          };
          reader.readAsDataURL(image);
        } else {
          this.imageUrl = '';
        }
        this.selectedFileImg = image;
      })
    );
  }

  show(event: MouseEvent) {
    this.showModal = true;
    this.imageService.setMostra(true);
    event.stopPropagation();
  }

  deleteBancheDati(id: string[]) {
    this.check = id.map(Number);
    LoaderComponent.isLoading = true;
    if (this.check !== null) {
      this.banchedati.deleteBancheDati(id).subscribe(
        (response) => {
          this.paginateBancheDati();
          this.elimina = false;

          this.selectionService.deselectAll();
          this.selectionService.getSelectedIds();
          this.updateSelectionAll();
          this.showSlide = false;
          LoaderComponent.isLoading = false;
        },
        (error) => {
          this.showMessage(error.error.message, 'error');
          LoaderComponent.isLoading = false;
          console.error(
            `Errore durante l'eliminazione del bando con ID ${this.idToDelete}:`,
            error
          );
        }
      );
    }
  }

  duplicateBancaDati() {
    LoaderComponent.isLoading = true;
    return this.banchedati.duplicaBancaDati(this.duplicateID).subscribe(
      (response: any) => {
        this.paginateBancheDati();
        this.duplicateID = [];
        this.check = [];
        this.selectionService.clearSelection();
        this.selectionService.updateCheck(false);
        this.updateSelectionAll();
        this.showSlide = false;
        LoaderComponent.isLoading = false;
      },
      (error: any) => {
        this.showMessage(error.error.message, 'error');
        LoaderComponent.isLoading = false;
        console.error('Errore ', error);
      }
    );
  }

  exportBancaDati(id: number[]) {
    LoaderComponent.isLoading = true;
    this.idToExport = id;
    if (this.idToExport !== null) {
      this.banchedati.exportBancaDati(this.idToExport).subscribe(
        (response: any) => {
          this.downloadFile(response.body);
          this.paginateBancheDati();
          this.check = [];
          this.selectionService.clearSelection();
          this.updateSelectionAll();
          this.showSlide = false;
          LoaderComponent.isLoading = false;
        },
        (error) => {
          console.error(`Errore durante il download del file:`, error);
          this.showMessage(error.error.message, 'error');
          LoaderComponent.isLoading = false;
        }
      );
    }
  }

  downloadFile(data: Blob) {
    const blob = new Blob([data], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'nome_del_file');
    document.body.appendChild(link);
    link.click();
    link.onload = () => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    };
  }

  perTeId: number[] = [];

  setPerTe() {
    LoaderComponent.isLoading = true;

    const checkStrings = this.check.map((num) => num.toString()); // Convert number[] to string[]
    return this.banchedati.perTe(checkStrings.join(',')).subscribe(
      (response: any) => {
        this.selectionService.deselectAll();
        this.selectionService.getSelectedIds();
        this.updateSelectionAll();
        this.showSlide = false;
        LoaderComponent.isLoading = false;
        this.paginateBancheDati();
      },
      (error: any) => {
        this.showMessage(error.error.message, 'error');
        LoaderComponent.isLoading = false;
        console.error('Errore durante la creazione della materia:', error);
      }
    );
  }
}
