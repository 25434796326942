import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { constant } from '@assets/constant';
import {
  faMagnifyingGlass,
  faPencil,
  faFilter,
  faXmark,
  faTrashCan,
  faChevronDown,
  faChevronUp,
  faTrash,
  faCirclePlus,
  faCopy,
  faUpload,
  faD,
  faN,
  faArrowUp,
  faArrowDown,
  faArrowUpAZ,
  faArrowDownZA,
  faArrowUp19,
  faArrowDown91,
  faSort,
} from '@fortawesome/free-solid-svg-icons';
import { TranslocoService } from '@ngneat/transloco';
import { BanchedatiService } from '@services/banchedati.service';
import { SelectionService } from '@services/selection.service';
import * as moment from 'moment';
import {
  BehaviorSubject,
  Subscription,
  forkJoin,
  interval,
  map,
  take,
  takeWhile,
} from 'rxjs';
import { HostListener } from '@angular/core';
import { ImageServiceService } from '@services/image-service.service';
import { MappingService } from '@services/mapping.service';

import { ModalFormService } from '@services/modal-form.service';
import { ModaleCreaService } from '@services/modale-crea.service';
import { MessageService } from '@services/message.service';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { ceil } from 'lodash';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  public faMagnifyingGlass = faMagnifyingGlass;
  public faFilter = faFilter;
  public faXmark = faXmark;
  public faTrashCan = faTrashCan;
  public faPencil = faPencil;
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  public faTrash = faTrash;
  public faCirclePlus = faCirclePlus;
  public faCopy = faCopy;
  public faUpload = faUpload;
  public faArrowUp = faArrowUp;
  public faArrowDown = faArrowDown;
  public faD = faD;
  public faN = faN;
  public faArrowUpAZ = faArrowUpAZ;
  public faArrowDownZA = faArrowDownZA;
  public faArrowUp19 = faArrowUp19;
  public faArrowDown91 = faArrowDown91;
  public faSort = faSort;
  ordinaPer: number = 0;
  ordinaDesc: boolean = false;
  icona = faFilter;
  tabTitle = 'Filtri Selezione';
  showAdmin: boolean = false;
  isPhone: any;
  search: string = '';
  allTranslation: any = false;
  roleId = localStorage.getItem('roleId');
  showSlide: boolean = false;
  showFilter: boolean = false;
  showVisual: boolean = false;
  show1: boolean = false;
  show2: boolean = false;
  show3: boolean = false;
  show4: boolean = false;
  show5: boolean = false;
  show6: boolean = false;
  show7: boolean = false;
  show8: boolean = false;
  enti: any[] = [];
  listaEnti: any[] = [];
  nomiEnti: any;
  selectedEnti: any;
  selectedDiff: any;
  selectedTit: any;
  initialSelectedEnti: number[] = [];
  profili: any[] = [];
  listaProfili: any[] = [];
  initialStateSelectedProfili: number[] = [];
  selectedProfiliFiltro!: number[];
  perTeId: number[] = [];
  nomiProfili: any;
  titolo: string = '';
  index: string = '';
  public imageUrl: any;
  showModal: boolean = false;
  selectedFile: any = null;
  private subscription: Subscription = new Subscription();
  descrizioneModal: string = '';
  import: boolean = false;
  loader: boolean = false;
  idToModify!: number;
  contenutoToModify: string = '';
  rispostaAToModify: any;
  rispostaBToModify: any;
  rispostaCToModify: any;
  rispostaDToModify: any;
  rispostaEToModify: any;
  rispostaFToModify: any;
  correttaToModify: any;
  numerazioneMidaToModify: any;
  titoloDiStudioToModify: any;
  annoToModify: any;
  enteToModify: any;
  profiloToModify: any;
  materiaToModify: any;
  argomentoToModify: any;
  difficoltaToModify: any;
  immagineToModify: string = '';
  immagineSpiegazioneRispostaToModify: string = '';
  linkVideoSpiegazioneToModify: string = '';
  linkPreparazioneToModify: string = '';
  contenutoCheck: string = '';
  rispostaACheck: string = '';
  rispostaBCheck: string = '';
  rispostaCCheck: string = '';
  rispostaDCheck: string = '';
  rispostaECheck: string = '';
  rispostaFCheck: string = '';
  correttaCheck: string = '';
  numerazioneMidaCheck: string = '';
  titoloDiStudioCheck: string = '';
  annoCheck: string = '';
  enteCheck: string = '';
  profiloCheck: string = '';
  materiaCheck: string = '';
  argomentoCheck: string = '';
  difficoltaCheck: number = 0;
  immagineCheck: string = '';
  immagineSpiegazioneRispostaCheck: string = '';
  linkPreparazioneCheck: string = '';
  changeId: number[] = [];
  librerie: any[] = [];
  listaCover: any[] = [];
  listaArgomenti: any[] = [];
  listaMaterie: any[] = [];
  nomiMaterie: any;
  regioni: any[] = [];
  listaRegioni: any[] = [];
  nomiRegioni: any;
  private regioniSubject = new BehaviorSubject<any[]>([]);
  public regioni$ = this.regioniSubject.asObservable();
  initialStateSelectedRegioni: number[] = [];
  selectedRegioniFiltro: number[] = [];
  selectedRegione: any;
  selectedRegioni: number[] = [];
  selectedMaterie: any;
  selectedProfilo: any;
  selectedAnniFiltro: string[] = [];
  selectedAnni: any[] = [];
  selectedNomiMaterieFiltro: string[] = [];
  selectedNomiEntiFiltro: string[] = [];
  selectedNomiArgomentiFiltro: string[] = [];
  selectedNomiDiffFiltro: string[] = [];
  selectedNomiTitFiltro: string[] = [];
  displayCount = 0;
  check: string[] = [];
  initialValue = false;
  idCreate: any = [];
  filtri: any[] = [];
  filtriQuiz: any[] = [];
  confirm: boolean = false;

  filtriId = {
    ente: [] as any,
    profilo: [] as any,
    materia: [] as any,
    anno: [] as any,
    argomento: [] as any,
    difficolta: [] as any,
    titoli: [] as any,
  };
  selectedEnte: any;
  combinedEnti!: string[];
  selectedMateria: any;
  selectedArgomento: any;
  selectedDifficolta: any;
  selectedArgomenti: any;
  selectedProfili: any;
  selectedNomiProfiliFiltro: string[] = [];
  selectedEnteRiga: any;
  selectedProfiloRiga: any;
  combinedProfili!: string[];
  selectedTitoli: any;
  selectedMateriaRiga: any;
  Anno: any[] = [];
  selectedAnno: any;
  selectedAnnoFiltro: any;
  selectedArgomentoRiga: any;
  listaQuiz: any = [];
  filtriAnno!: string[];
  listaId: any = [];
  listaIdCrea: any = [];
  @ViewChild('scrollingDiv') scrollingDiv!: ElementRef;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  public fileUrl: any | null = null;
  selectedFileXl: any = null;
  domandeIds: number[] = [];
  showDomande: boolean = false;
  modifica2: boolean = false;
  elimina: boolean = false;
  domanda: string = '';
  rispostaA: string = '';
  rispostaB: string = '';
  rispostaC: string = '';
  rispostaD: string = '';
  rispostaE: string = '';
  rispostaF: string = '';
  rispostaCorretta: string = '';
  numerazioneMida: string = '';
  titoloDiStudio: string = '';
  annoRiga: string = '';
  enteRiga: string = '';
  profiloRiga: string = '';
  materia: string = '';
  argomento: string = '';
  difficoltaDomanda: number = 0;
  immagineRiga: string = '';
  immagineSpiegazioneRisposta: string = '';
  linkVideoSpiegazione: string = '';
  linkPreparazione: string = '';
  immagine: string = '';
  showInput: boolean = false;
  selectedTitoliRiga: any;
  selectedAnnoRiga: any;
  checkHeader: string = 'sopratab';
  checkBody: string = 'check-corpo';
  tabText: string = 'tab-text';
  headerText: string = 'header-text';
  titles: any[] = [
    { title: 'indice', value: 'indice' },
    { title: 'domanda', value: 'domanda' },
    { title: 'risposta a', value: 'rispostaA' },
    { title: 'risposta b', value: 'rispostaB' },
    { title: 'risposta c', value: 'rispostaC' },
    { title: 'risposta d', value: 'rispostaD' },
    { title: 'risposta e', value: 'rispostaE' },
    { title: 'risposta f', value: 'rispostaF' },
    { title: 'corretta', value: 'corretta' },
    { title: 'numerazione mida', value: 'numerazioneMida' },
    { title: 'titolo di studio', value: 'titoloStudio' },
    { title: 'anno', value: 'anno' },
    { title: 'ente', value: 'ente' },
    { title: 'profilo', value: 'profilo' },
    { title: 'materia', value: 'materia' },
    { title: 'argomento', value: 'argomento' },
    { title: 'difficolta', value: 'difficolta' },
    { title: 'immagine', value: 'cover' },
    {
      title: 'Testo spiegazione domanda',
      value: 'immagineSpiegazioneRisposta',
    },
    { title: 'link preparazione', value: 'linkPreparazione' },
  ];
  larghezza = { width: 'fit-content' };
  idBanche!: number;
  isScrollable: boolean = true;
  existPass: boolean = false;
  listaDifficolta: any[] = [
    {
      id: null,
      nome: '',
    },
    {
      id: 1,
      nome: 'Facile',
    },
    {
      id: 2,
      nome: 'Medio',
    },
    {
      id: 3,
      nome: 'Difficile',
    },
  ];

  listaTitoliStudio: any[] = [
    {
      id: 1,
      nome: 'Diploma 1° ',
    },
    {
      id: 2,
      nome: 'Diploma 2°',
    },
    {
      id: 3,
      nome: 'Laurea',
    },
    {
      id: 4,
      nome: 'Master',
    },
  ];
  initialPageX: number = 0;
  page: number = 1;
  realPage: number = 1;
  realPageBefore: number = -1;
  limit: number = 10;
  totalPages: number = 1;

  constructor(
    private mappingService: MappingService,
    private transloco: TranslocoService,
    private banchedati: BanchedatiService,
    private selectionService: SelectionService,
    private imageService: ImageServiceService,
    private message: MessageService,
    private modalForm: ModalFormService,
    private formService: ModaleCreaService,
    private renderer: Renderer2
  ) {}

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (this.showFilter) {
      this.showFilter = !this.showFilter;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show5 = false;
      this.show6 = false;
      this.show7 = false;
      this.show8 = false;
      this.resetta_filtro();
      this.getAllQuestionsId();
    }
    if (this.import) {
      this.import = !this.import;
      this.titolo = '';
      this.descrizioneModal = '';
      this.selectedEnte = null;
      this.selectedProfilo = null;
      this.selectedRegione = null;
      this.selectedAnno = null;
      this.imageService.setCroppedImage(null);
    }
    if (this.showModal === true) {
      this.showModal = false;
      this.imageService.setMostra(false);
    }
    if (this.showDomande === true) {
      this.showDomande = !this.showDomande;
    }
    if (this.modifica2 === true) {
      this.modifica2 = !this.modifica2;
    }
    if (this.elimina === true) {
      this.elimina = !this.elimina;
    }
    if (this.showInput === true) {
      this.showInput = false;
    }
    if (this.showVisual === true) {
      this.showVisual = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.imageService.setCroppedImage(null);
    this.selectionService.clearSelection();
    this.modalForm.setFormError(false);
    this.mappingService.setEnteID(0);
    this.mappingService.setFilter('');
    this.mappingService.avvia().subscribe();
  }

  avvia() {
    this.getLib();
    this.getEnte();
    this.getProfili();
    this.getRegioni();
    this.getMateria();
    this.getCover();
    this.getArgomento();
    this.getAnno();
    this.getTitoli();
    this.getTitoliDiStudio();
    this.getAllQuestionsId();
  }

  getEnte() {
    this.subscription.add(
      this.mappingService.getListaEnti().subscribe((enti) => {
        this.listaEnti = enti;
      })
    );
    this.subscription.add(
      this.mappingService.getNomiEnti().subscribe((nomi) => {
        this.nomiEnti = nomi;
        this.combinedEnti = [...this.nomiEnti];
      })
    );
  }

  getTitoliDiStudio() {
    this.subscription.add(
      this.mappingService.getListaTitoliDiStudio().subscribe((titoli) => {
        this.listaTitoliStudio = titoli;
      })
    );
  }
  combinedAnno: any;
  getAnno() {
    this.subscription.add(
      this.mappingService.getListaAnni().subscribe((anni) => {
        this.Anno = anni;
        this.combinedAnno = this.Anno.map((anno) => anno.nome);
      })
    );
  }

  combinedTitoli: any[] = [];
  combinedMaterie: any[] = [];

  getTitoli() {
    this.subscription.add(
      this.mappingService.getListaTitoliDiStudio().subscribe((titoli) => {
        this.listaTitoliStudio = titoli;
        this.combinedTitoli = this.listaTitoliStudio.map(
          (titolo) => titolo.nome
        );
      })
    );
  }

  getProfili() {
    this.subscription.add(
      this.mappingService.getListaProfili().subscribe((profili) => {
        this.listaProfili = profili;
      })
    );
    this.subscription.add(
      this.mappingService.getNomiProfili().subscribe((nomi) => {
        this.nomiProfili = nomi;
        this.combinedProfili = [...this.nomiProfili];
      })
    );
  }
  getRegioni() {
    this.subscription.add(
      this.mappingService.getListaRegioni().subscribe((regioni) => {
        this.listaRegioni = regioni;
      })
    );
  }

  getLib() {
    this.subscription.add(
      this.mappingService.getLibrerie().subscribe((lib) => {
        this.librerie = lib;
      })
    );
  }

  getCover() {
    this.subscription.add(
      this.mappingService.getListaCover().subscribe((cover) => {
        this.listaCover = cover;
      })
    );
  }

  getMateria() {
    this.subscription.add(
      this.mappingService.getListaMaterie().subscribe((materie) => {
        this.listaMaterie = materie;
        this.combinedMaterie = this.listaMaterie.map((materia) => materia.nome);
      })
    );
  }

  combinedArgomenti: any[] = [];

  getArgomento() {
    this.subscription.add(
      this.mappingService.getListaArgomenti().subscribe((argomenti) => {
        this.listaArgomenti = argomenti;
        this.combinedArgomenti = this.listaArgomenti.map(
          (argomento) => argomento.nome
        );
      })
    );
  }
  widths: number[] = [];

  @ViewChild('slider2', { static: false }) slider2!: ElementRef;
  private alive = true;

  ngOnInit(): void {
    this.avvia();
    this.form();
    this.subscription.add(this.mappingService.profilo().subscribe());
    this.subscription.add(this.mappingService.argomento().subscribe());

    this.subscription.add(
      this.formService.getExistPass().subscribe((value) => {
        this.existPass = value;
      })
    );

    if (localStorage.getItem('roleId') === '3') {
      this.showAdmin = true;
    }

    this.transloco.selectTranslation().subscribe({
      next: (translation: any) => {
        this.allTranslation = translation;
      },
    });
    this.subscription.add(
      this.imageService.getCroppedImage().subscribe((image) => {
        if (image instanceof File) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
          };
          reader.readAsDataURL(image);
        } else {
          this.imageUrl = '';
        }
        this.selectedFile = image;
      })
    );
    this.subscription.add(
      this.imageService.getMostra().subscribe((mostra) => {
        this.showModal = mostra;
      })
    );
    this.subscription.add(
      this.formService.getImport().subscribe((importa) => {
        this.import = importa;
      })
    );

    this.subscription.add(
      this.formService.getConfirm().subscribe((confirm) => {
        this.confirm = confirm;
      })
    );
  }

  importaBanca(event: MouseEvent) {
    this.idCreate = [
      ...new Set([
        ...this.filtri.flatMap((filtro: any) => filtro.id),
        ...this.check,
      ]),
    ];

    event.stopPropagation();
    this.formService.setImport(true);
  }

  show(event: MouseEvent) {
    this.showModal = true;
    this.imageService.setMostra(true);
    event.stopPropagation();
  }

  removeImage() {
    this.imageService.setCroppedImage(null);
  }

  handleSwitchChange(isChecked: boolean) {}

  applyFilter() {
    this.getAllQuestionsId();
  }

  applyIndex(event: any) {
    if (event.key == 'Enter') {
      const index = Number(this.index) - 1;
      const calcPage = ceil(index / 10);
      console.log('CALC PAGE:', calcPage > 0 ? calcPage - 1 : calcPage);

      this.realPageBefore = calcPage > 0 ? calcPage - 1 : calcPage;
      this.page = calcPage > 0 ? calcPage - 1 : calcPage;
      this.realPage = calcPage > 0 ? calcPage - 1 : calcPage;
      if (calcPage >= 0) {
        LoaderComponent.isLoading = true;
        let domandeObservable;

        const filtriOpen = [
          {
            idEnte: this.selectedEnti ? this.selectedEnti : undefined,
            idProfilo: this.selectedProfili ? this.selectedProfili : undefined,
            idMateria: this.selectedMaterie ? this.selectedMaterie : undefined,
            anno: this.selectedAnni.length > 0 ? this.selectedAnni : undefined,
            idArgomento: this.selectedArgomenti
              ? this.selectedArgomenti
              : undefined,
            difficolta: this.selectedDiff ? this.selectedDiff : undefined,
            idTitoloStudio: this.selectedTit ? this.selectedTit : undefined,
          },
        ];

        if (this.showFilter === true) {
          domandeObservable = this.banchedati.getPaginateQuestions(
            this.search,
            filtriOpen.length > 0 ? filtriOpen : (null as unknown as any[]),
            this.ordinaPer,
            this.ordinaDesc,
            calcPage > 0 ? calcPage - 1 : calcPage,
            this.limit
          );
        } else {
          domandeObservable = this.banchedati.getPaginateQuestions(
            this.search,
            this.filtriQuiz.length > 0
              ? this.filtriQuiz
              : (null as unknown as any[]),
            this.ordinaPer,
            this.ordinaDesc,
            calcPage > 0 ? calcPage - 1 : calcPage,
            this.limit
          );
        }

        domandeObservable.subscribe(
          (response: any) => {
            this.totalPages = response.totalPages;
            this.listaQuiz = [
              ...response.data.map((response: any, index: number) => ({
                indice: response.indice,
                id: response.id,
                domanda: response.contenuto,
                rispostaA: this.getRispostaByTag(response.risposte, 'A'),
                rispostaB: this.getRispostaByTag(response.risposte, 'B'),
                rispostaC: this.getRispostaByTag(response.risposte, 'C'),
                rispostaD: this.getRispostaByTag(response.risposte, 'D'),
                rispostaE: this.getRispostaByTag(response.risposte, 'E'),
                rispostaF: this.getRispostaByTag(response.risposte, 'F'),
                corretta: response.rispostaCorretta,
                correttaTag: response.risposte.find(
                  (corretta: { corretta: boolean }) => corretta.corretta == true
                )?.tag,
                numerazioneMida: response.numerazioneMida || 'Non disponibile',
                titoloStudio: response.titoloStudio || 'Non disponibile',
                anno: response.anno || 'Non disponibile',
                materia: response.materia?.nome || 'Non disponibile',
                argomento: response.argomento?.nome || 'Non disponibile',
                difficolta: response.difficolta || 'Non disponibile',
                profilo: response?.profilo?.nome || 'Non disponibile',
                ente: response?.ente?.nome || 'Non disponibile',
                cover:
                  response.immagine === 'Non disponibile'
                    ? ''
                    : response.immagine,
                immagineSpiegazioneRisposta:
                  response.immagineSpiegazioneRisposta || 'Non disponibile',
                linkPreparazione:
                  response.linkPreparazione || 'Non disponibile',
              })),
            ];
            setTimeout(() => {
              LoaderComponent.isLoading = false;
              this.selectionService.setIndexView(index);
            }, 0);
          },
          (error: any) => {
            LoaderComponent.isLoading = false;
            console.error(
              'Errore durante il recupero delle informazioni delle notifiche',
              error
            );
          }
        );
      }
    }
  }

  pippo() {
    return this.check.length > 0 ? this.check.length : 0;
  }

  filtriSelect: any = {};

  return_filter() {
    this.filtriSelect = {
      idEnte: this.selectedEnti ? this.selectedEnti : undefined,
      idProfilo: this.selectedProfili ? this.selectedProfili : undefined,
      idMateria: this.selectedMaterie ? this.selectedMaterie : undefined,
      anno: this.selectedAnni.length > 0 ? this.selectedAnni : undefined,
      idArgomento: this.selectedArgomenti ? this.selectedArgomenti : undefined,
      difficolta: this.selectedDiff ? this.selectedDiff : undefined,
      idTitoloStudio: this.selectedTit ? this.selectedTit : undefined,
    };
  }

  addSelectedAnni(selectedObject: any) {
    if (selectedObject) {
      this.selectedAnni = selectedObject;
    }
    this.return_filter();
    const filtro = JSON.stringify(this.filtriSelect);
    this.mappingService.setFilter(filtro);
    this.mappingService.avvia().subscribe();
    this.getAllQuestionsId();
  }

  addSelectedMaterie(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedMaterie = selectedObject.id;
      this.selectedNomiMaterieFiltro = [selectedObject.nome];
    }
    this.mappingService.setMateriaID(selectedObject.id);
    this.mappingService.argomento().subscribe();
    this.return_filter();
    const filtro = JSON.stringify(this.filtriSelect);
    this.mappingService.setFilter(filtro);
    this.mappingService.avvia().subscribe();
    this.getAllQuestionsId();
  }

  addSelectedArgomenti(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedArgomenti = selectedObject.id;
      this.selectedNomiArgomentiFiltro = [selectedObject.nome];
    }
    this.return_filter();
    const filtro = JSON.stringify(this.filtriSelect);
    this.mappingService.setFilter(filtro);
    this.mappingService.avvia().subscribe();
    this.getAllQuestionsId();
  }

  addSelectedDifficolta(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedDiff = selectedObject.id;
      this.selectedNomiDiffFiltro = [selectedObject.nome];
    }
    this.return_filter();
    const filtro = JSON.stringify(this.filtriSelect);
    this.mappingService.setFilter(filtro);
    this.mappingService.avvia().subscribe();
    this.getAllQuestionsId();
  }

  addSelectedTitoli(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedTit = selectedObject.id;
      this.selectedNomiTitFiltro = [selectedObject.nome];
    }
    this.return_filter();
    const filtro = JSON.stringify(this.filtriSelect);
    this.mappingService.setFilter(filtro);
    this.mappingService.avvia().subscribe();
    this.getAllQuestionsId();
  }

  addSelectedEnte(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedEnti = selectedObject.id;
      this.selectedNomiEntiFiltro = [selectedObject.nome];
    }
    this.mappingService.setEnteID(selectedObject.id);
    this.mappingService.profilo().subscribe();
    this.return_filter();
    const filtro = JSON.stringify(this.filtriSelect);
    this.mappingService.setFilter(filtro);
    this.mappingService.avvia().subscribe();
    this.getAllQuestionsId();
  }

  addSelectedProfili(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedProfili = selectedObject.id;
      this.selectedNomiProfiliFiltro = [selectedObject.nome];
    }
    this.return_filter();
    const filtro = JSON.stringify(this.filtriSelect);
    this.mappingService.setFilter(filtro);
    this.mappingService.avvia().subscribe();
    this.getAllQuestionsId();
  }

  altriFiltri: any[] = [];

  aggiungi() {
    this.listaIdCrea = this.getUniqueIds(this.listaIdCrea, this.listaId);

    const nuovoFiltro = {
      idEnte: this.selectedEnti ? this.selectedEnti : undefined,
      idProfilo: this.selectedProfili ? this.selectedProfili : undefined,
      idMateria: this.selectedMaterie ? this.selectedMaterie : undefined,
      anno: this.selectedAnni.length > 0 ? this.selectedAnni : undefined,
      idArgomento: this.selectedArgomenti ? this.selectedArgomenti : undefined,
      difficolta: this.selectedDiff ? this.selectedDiff : undefined,
      idTitoloStudio: this.selectedTit ? this.selectedTit : undefined,
    };

    const nomiFiltro = {
      ente: this.selectedNomiEntiFiltro,
      profilo: this.selectedNomiProfiliFiltro,
      materia: this.selectedNomiMaterieFiltro,
      anno: this.selectedAnni,
      argomento: this.selectedNomiArgomentiFiltro,
      difficolta: this.selectedNomiDiffFiltro,
      titoli: this.selectedNomiTitFiltro,
      id: this.listaIdCrea,
    };

    this.filtri.push(nomiFiltro);
    this.filtriQuiz.push(nuovoFiltro);

    // Se non esiste, crealo
    if (!this.altriFiltri) {
      this.altriFiltri = [];
    }

    // Aggiungi il nuovo filtro all'array
    this.altriFiltri.push(nuovoFiltro);

    // Aggiungi o aggiorna i valori
    this.filtriId.ente.push(this.selectedEnti);
    this.filtriId.profilo.push(this.selectedProfili);
    this.filtriId.materia.push(this.selectedMaterie);
    this.filtriId.anno.push(this.selectedAnni);
    this.filtriId.argomento.push(this.selectedArgomenti);
    this.filtriId.difficolta.push(this.selectedDiff);
    this.filtriId.titoli.push(this.selectedNomiTitFiltro);

    console.log(this.filtri);
    console.log('altri', this.altriFiltri);
    // this.getAllQuestionsId();
    this.resetta_filtro();
  }

  getUniqueIds(array1: any[], array2: any[]): any[] {
    return [...new Set(array1.concat(array2))];
  }

  reset() {
    this.filtri = [];
    this.filtriQuiz = [];
    this.listaIdCrea = [];
    this.filtriId = {
      ente: [],
      profilo: [],
      materia: [],
      anno: [],
      argomento: [],
      difficolta: [],
      titoli: [],
    };
    this.getAllQuestionsId();
  }

  resetta_filtro() {
    this.listaIdCrea = [];
    this.selectedEnte = '';
    this.selectedProfilo = '';
    this.selectedMateria = '';
    this.selectedAnno = '';
    this.selectedArgomento = '';
    this.selectedDifficolta = '';
    this.selectedTitoli = '';
    this.selectedTit = null;
    this.selectedDiff = null;
    this.selectedAnni = [];
    this.selectedMaterie = null;
    this.selectedNomiMaterieFiltro = [];
    this.selectedEnti = null;
    this.selectedNomiEntiFiltro = [];
    this.selectedProfili = null;
    this.selectedNomiProfiliFiltro = [];
    this.selectedArgomenti = null;
    this.selectedNomiArgomentiFiltro = [];
    this.selectedNomiDiffFiltro = [];
    this.selectedNomiTitFiltro = [];
    this.mappingService.setFilter('');
    this.mappingService.avvia().subscribe();
    this.getAllQuestionsId();
  }

  rimuoviElemento(indice: number) {
    this.filtri.splice(indice, 1);
    this.filtriQuiz.splice(indice, 1);
    this.filtriId.anno.splice(indice, 1);
    this.filtriId.materia.splice(indice, 1);
    this.filtriId.argomento.splice(indice, 1);
    this.filtriId.difficolta.splice(indice, 1);
    this.filtriId.profilo.splice(indice, 1);
    this.filtriId.titoli.splice(indice, 1);
    this.filtriId.ente.splice(indice, 1);

    this.selectedAnno = null;
    this.getAllQuestionsId();
  }

  onSelectedProfilo(selectedProf: any) {
    if (
      selectedProf &&
      selectedProf.id !== 'Seleziona o inserisci il profilo'
    ) {
      this.selectedProfilo = selectedProf;
    }
  }

  onSelectedMateria(selectedMat: any) {
    if (selectedMat && selectedMat.id !== 'Seleziona o inserisci la materia') {
      this.selectedMateria = selectedMat;
    }
  }

  onSelectedMateriaRiga(selectedValue: string) {
    if (selectedValue && selectedValue !== 'Seleziona o inserisci l’ente') {
      this.selectedMateriaRiga = selectedValue;
    }
  }

  onSelectedArgomentoRiga(selectedValue: string) {
    if (selectedValue && selectedValue !== 'Seleziona o inserisci l’ente') {
      this.selectedArgomentoRiga = selectedValue;
    }
  }

  onSelectedAnno(selectedValue: string) {
    if (selectedValue && selectedValue !== 'Seleziona o inserisci l’ente') {
      this.selectedAnno = selectedValue;
    }
  }

  getRispostaByTag(risposte: any[], tag: string) {
    return (
      risposte.find((risposta) => risposta.tag == tag)?.contenuto ||
      'Non disponibile'
    );
  }

  getArrayOrUndefined(array: any[]): any[] | undefined {
    return array.length > 0 ? array : undefined;
  }

  getArrayOrUndefined2(array: any[] | undefined) {
    return array && array.length > 0 ? array : undefined;
  }

  openFilter() {
    this.showFilter = !this.showFilter;
    if (this.showFilter === false) {
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show5 = false;
      this.show6 = false;
      this.show7 = false;
      this.show8 = false;
      this.resetta_filtro();
      this.getAllQuestionsId();
    }
  }

  openVisual() {
    this.showVisual = !this.showVisual;
  }

  resetOrdine() {
    this.ordinaPer = 0;
    this.ordinaDesc = false;
    this.index = '0';
    this.selectionService.setDisplayCount(10);
    this.selectionService.setIndexView(0);
    this.getAllQuestionsId();
  }

  getAllQuestionsId() {
    LoaderComponent.isLoading = true;
    let domandeObservable;

    const filtriOpen = [
      {
        idEnte: this.selectedEnti ? this.selectedEnti : undefined,
        idProfilo: this.selectedProfili ? this.selectedProfili : undefined,
        idMateria: this.selectedMaterie ? this.selectedMaterie : undefined,
        anno: this.selectedAnni.length > 0 ? this.selectedAnni : undefined,
        idArgomento: this.selectedArgomenti
          ? this.selectedArgomenti
          : undefined,
        difficolta: this.selectedDiff ? this.selectedDiff : undefined,
        idTitoloStudio: this.selectedTit ? this.selectedTit : undefined,
      },
    ];

    if (this.showFilter === true) {
      domandeObservable = this.banchedati.getDomande(
        this.search,
        filtriOpen.length > 0 ? filtriOpen : (null as unknown as any[]),
        this.ordinaPer,
        this.ordinaDesc
      );
    } else {
      domandeObservable = this.banchedati.getDomande(
        this.search,
        this.filtriQuiz.length > 0
          ? this.filtriQuiz
          : (null as unknown as any[]),
        this.ordinaPer,
        this.ordinaDesc
      );
    }

    domandeObservable.subscribe(
      (response: any) => {
        this.listaId = response;
        LoaderComponent.isLoading = false;
        this.getPaginatedQuestions();
      },
      (error: any) => {
        LoaderComponent.isLoading = false;
        console.error(
          'Errore durante il recupero delle informazioni delle notifiche',
          error
        );
      }
    );
  }

  getPaginatedQuestions() {
    LoaderComponent.isLoading = true;
    let domandeObservable;

    const filtriOpen = [
      {
        idEnte: this.selectedEnti ? this.selectedEnti : undefined,
        idProfilo: this.selectedProfili ? this.selectedProfili : undefined,
        idMateria: this.selectedMaterie ? this.selectedMaterie : undefined,
        anno: this.selectedAnni.length > 0 ? this.selectedAnni : undefined,
        idArgomento: this.selectedArgomenti
          ? this.selectedArgomenti
          : undefined,
        difficolta: this.selectedDiff ? this.selectedDiff : undefined,
        idTitoloStudio: this.selectedTit ? this.selectedTit : undefined,
      },
    ];

    if (this.showFilter === true) {
      domandeObservable = this.banchedati.getPaginateQuestions(
        this.search,
        filtriOpen.length > 0 ? filtriOpen : (null as unknown as any[]),
        this.ordinaPer,
        this.ordinaDesc,
        this.page,
        this.limit
      );
    } else {
      domandeObservable = this.banchedati.getPaginateQuestions(
        this.search,
        this.filtriQuiz.length > 0
          ? this.filtriQuiz
          : (null as unknown as any[]),
        this.ordinaPer,
        this.ordinaDesc,
        this.page,
        this.limit
      );
    }

    domandeObservable.subscribe(
      (response: any) => {
        this.totalPages = response.totalPages;
        this.listaQuiz = [
          ...response.data.map((response: any, index: number) => ({
            indice: index + (this.page - 1) * this.limit + 1,
            id: response.id,
            domanda: response.contenuto,
            rispostaA: this.getRispostaByTag(response.risposte, 'A'),
            rispostaB: this.getRispostaByTag(response.risposte, 'B'),
            rispostaC: this.getRispostaByTag(response.risposte, 'C'),
            rispostaD: this.getRispostaByTag(response.risposte, 'D'),
            rispostaE: this.getRispostaByTag(response.risposte, 'E'),
            rispostaF: this.getRispostaByTag(response.risposte, 'F'),
            corretta: response.rispostaCorretta,
            correttaTag: response.risposte.find(
              (corretta: { corretta: boolean }) => corretta.corretta == true
            )?.tag,
            numerazioneMida: response.numerazioneMida || 'Non disponibile',
            titoloStudio: response.titoloStudio || 'Non disponibile',
            anno: response.anno || 'Non disponibile',
            materia: response.materia?.nome || 'Non disponibile',
            argomento: response.argomento?.nome || 'Non disponibile',
            difficolta: response.difficolta || 'Non disponibile',
            profilo: response?.profilo?.nome || 'Non disponibile',
            ente: response?.ente?.nome || 'Non disponibile',
            cover:
              response.immagine === 'Non disponibile' ? '' : response.immagine,
            immagineSpiegazioneRisposta:
              response.immagineSpiegazioneRisposta || 'Non disponibile',
            linkPreparazione: response.linkPreparazione || 'Non disponibile',
          })),
        ];
        LoaderComponent.isLoading = false;
      },
      (error: any) => {
        LoaderComponent.isLoading = false;
        console.error(
          'Errore durante il recupero delle informazioni delle notifiche',
          error
        );
      }
    );
  }

  getBeforePaginatedQuestions() {
    if (this.realPageBefore > 0) {
      this.realPageBefore = this.realPageBefore - 1;
      LoaderComponent.isLoading = true;
      let domandeObservable;

      const filtriOpen = [
        {
          idEnte: this.selectedEnti ? this.selectedEnti : undefined,
          idProfilo: this.selectedProfili ? this.selectedProfili : undefined,
          idMateria: this.selectedMaterie ? this.selectedMaterie : undefined,
          anno: this.selectedAnni.length > 0 ? this.selectedAnni : undefined,
          idArgomento: this.selectedArgomenti
            ? this.selectedArgomenti
            : undefined,
          difficolta: this.selectedDiff ? this.selectedDiff : undefined,
          idTitoloStudio: this.selectedTit ? this.selectedTit : undefined,
        },
      ];

      if (this.showFilter === true) {
        domandeObservable = this.banchedati.getPaginateQuestions(
          this.search,
          filtriOpen.length > 0 ? filtriOpen : (null as unknown as any[]),
          this.ordinaPer,
          this.ordinaDesc,
          this.realPageBefore,
          this.limit
        );
      } else {
        domandeObservable = this.banchedati.getPaginateQuestions(
          this.search,
          this.filtriQuiz.length > 0
            ? this.filtriQuiz
            : (null as unknown as any[]),
          this.ordinaPer,
          this.ordinaDesc,
          this.realPageBefore,
          this.limit
        );
      }

      domandeObservable.subscribe(
        (response: any) => {
          this.realPage = this.page;
          this.listaQuiz = [
            ...response.data.map((response: any, index: number) => ({
              indice: response.indice,
              id: response.id,
              domanda: response.contenuto,
              rispostaA: this.getRispostaByTag(response.risposte, 'A'),
              rispostaB: this.getRispostaByTag(response.risposte, 'B'),
              rispostaC: this.getRispostaByTag(response.risposte, 'C'),
              rispostaD: this.getRispostaByTag(response.risposte, 'D'),
              rispostaE: this.getRispostaByTag(response.risposte, 'E'),
              rispostaF: this.getRispostaByTag(response.risposte, 'F'),
              corretta: response.rispostaCorretta,
              correttaTag: response.risposte.find(
                (corretta: { corretta: boolean }) => corretta.corretta == true
              )?.tag,
              numerazioneMida: response.numerazioneMida || 'Non disponibile',
              titoloStudio: response.titoloStudio || 'Non disponibile',
              anno: response.anno || 'Non disponibile',
              materia: response.materia?.nome || 'Non disponibile',
              argomento: response.argomento?.nome || 'Non disponibile',
              difficolta: response.difficolta || 'Non disponibile',
              profilo: response?.profilo?.nome || 'Non disponibile',
              ente: response?.ente?.nome || 'Non disponibile',
              cover:
                response.immagine === 'Non disponibile'
                  ? ''
                  : response.immagine,
              immagineSpiegazioneRisposta:
                response.immagineSpiegazioneRisposta || 'Non disponibile',
              linkPreparazione: response.linkPreparazione || 'Non disponibile',
            })),
            ...this.listaQuiz,
          ];
          if (this.check.length == this.listaId.length) {
            this.updateSelectionAll();
          }
          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          LoaderComponent.isLoading = false;
          console.error(
            'Errore durante il recupero delle informazioni delle notifiche',
            error
          );
        }
      );
    }
  }

  getNextPaginatedQuestions() {
    if (this.page == this.realPage && this.totalPages > this.realPage) {
      this.page = this.realPage + 1;
      LoaderComponent.isLoading = true;
      let domandeObservable;

      const filtriOpen = [
        {
          idEnte: this.selectedEnti ? this.selectedEnti : undefined,
          idProfilo: this.selectedProfili ? this.selectedProfili : undefined,
          idMateria: this.selectedMaterie ? this.selectedMaterie : undefined,
          anno: this.selectedAnni.length > 0 ? this.selectedAnni : undefined,
          idArgomento: this.selectedArgomenti
            ? this.selectedArgomenti
            : undefined,
          difficolta: this.selectedDiff ? this.selectedDiff : undefined,
          idTitoloStudio: this.selectedTit ? this.selectedTit : undefined,
        },
      ];

      if (this.showFilter === true) {
        domandeObservable = this.banchedati.getPaginateQuestions(
          this.search,
          filtriOpen.length > 0 ? filtriOpen : (null as unknown as any[]),
          this.ordinaPer,
          this.ordinaDesc,
          this.page,
          this.limit
        );
      } else {
        domandeObservable = this.banchedati.getPaginateQuestions(
          this.search,
          this.filtriQuiz.length > 0
            ? this.filtriQuiz
            : (null as unknown as any[]),
          this.ordinaPer,
          this.ordinaDesc,
          this.page,
          this.limit
        );
      }

      domandeObservable.subscribe(
        (response: any) => {
          this.realPage = this.page;
          this.listaQuiz = [
            ...this.listaQuiz,
            ...response.data.map((response: any, index: number) => ({
              indice: response.indice,
              id: response.id,
              domanda: response.contenuto,
              rispostaA: this.getRispostaByTag(response.risposte, 'A'),
              rispostaB: this.getRispostaByTag(response.risposte, 'B'),
              rispostaC: this.getRispostaByTag(response.risposte, 'C'),
              rispostaD: this.getRispostaByTag(response.risposte, 'D'),
              rispostaE: this.getRispostaByTag(response.risposte, 'E'),
              rispostaF: this.getRispostaByTag(response.risposte, 'F'),
              corretta: response.rispostaCorretta,
              correttaTag: response.risposte.find(
                (corretta: { corretta: boolean }) => corretta.corretta == true
              )?.tag,
              numerazioneMida: response.numerazioneMida || 'Non disponibile',
              titoloStudio: response.titoloStudio || 'Non disponibile',
              anno: response.anno || 'Non disponibile',
              materia: response.materia?.nome || 'Non disponibile',
              argomento: response.argomento?.nome || 'Non disponibile',
              difficolta: response.difficolta || 'Non disponibile',
              profilo: response?.profilo?.nome || 'Non disponibile',
              ente: response?.ente?.nome || 'Non disponibile',
              cover:
                response.immagine === 'Non disponibile'
                  ? ''
                  : response.immagine,
              immagineSpiegazioneRisposta:
                response.immagineSpiegazioneRisposta || 'Non disponibile',
              linkPreparazione: response.linkPreparazione || 'Non disponibile',
            })),
          ];
          if (this.check.length == this.listaId.length) {
            this.updateSelectionAll();
          }
          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          LoaderComponent.isLoading = false;
          console.error(
            'Errore durante il recupero delle informazioni delle notifiche',
            error
          );
        }
      );
    }
  }

  ordinaAlfabetico() {
    this.ordinaPer = 2;
    this.getAllQuestionsId();
  }

  ordinaIndice() {
    this.ordinaPer = 3;
    this.getAllQuestionsId();
  }

  ordinaNumerico() {
    this.ordinaPer = 1;
    this.getAllQuestionsId();
  }

  invertiOrdine() {
    this.ordinaDesc = !this.ordinaDesc;
    this.getAllQuestionsId();
  }

  updateSelectionAll() {
    if (this.check.length == 0) {
      this.listaId.forEach((id: any) => {
        this.check.push(id);
        this.domandeIds.push(id);
      });
    } else {
      this.check = [];
      this.domandeIds = [];
      this.showSlide = false;
    }
    return this.listaId;
  }

  updateSelection(event: any) {
    if (event?.indice) {
    } else {
      if (this.check.includes(event)) {
        let indexToDelete = this.check.findIndex(
          (myCheck: any) => myCheck === event
        );
        this.check.splice(indexToDelete, 1);
      } else {
        this.check.push(event);
      }
    }

    // const ids = [...this.selectionService.getSelectedIds()];
    // console.log(ids);

    // if (ids.length === 0) {
    //   //this.selectionService.deselectAll();
    //   // this.check = [];
    //   // this.domandeIds = [];
    //   // return ids;
    //   return this.check;
    // }

    return this.check;

    // let checkSet = new Set(this.check);
    // let domandeIdsSet = new Set(this.domandeIds);

    // ids.forEach((id) => {
    //   // Aggiungi l'ID al set check se non è già presente
    //   if (id !== 0) {
    //     checkSet.add(id);
    //     this.idBanche = id;
    //     domandeIdsSet.add(id);
    //   }
    // });

    // // Rimuovi l'ID da checkSet e domandeIdsSet se non è presente in ids
    // this.check.forEach((id) => {
    //   if (!ids.includes(id)) {
    //     checkSet.delete(id);
    //     domandeIdsSet.delete(Number(id));
    //   }
    // });

    // this.check = Array.from(checkSet);
    // this.domandeIds = Array.from(domandeIdsSet);

    // if (!ids.includes(this.idBanche)) {
    //   this.check = [];
    //   this.idBanche != null;
    //   this.domandeIds = [];
    // }

    // if (this.check.length === 0) {
    //   this.showSlide = false;
    // }
    // console.log('Check update', this.check.length);

    // return this.selectionService.getSelectedIds();
  }

  onButtonClick(event: MouseEvent) {
    if (this.fileInput) {
      const inputElement: HTMLInputElement = this.fileInput.nativeElement;
      inputElement.click();
    }
  }

  onFileSelected(event: any) {
    this.selectedFileXl = event.target.files[0];
    const input = event.target as HTMLInputElement;
    if (input?.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        this.fileUrl = reader.result as string;
        this.onSubmit(); // Sposta la chiamata a onSubmit() qui
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  onSubmit() {
    LoaderComponent.isLoading = true;
    if (this.selectedFileXl) {
      const formData = new FormData();

      formData.append('file', this.selectedFileXl);

      this.banchedati.importaQuiz(formData).subscribe(
        (response) => {
          this.getAllQuestionsId();
          LoaderComponent.isLoading = false;
        },
        (error) => {
          this.showMessage(error.error.message, 'error');
          LoaderComponent.isLoading = false;
          console.error('seleziona un file', error);
        }
      );
      console.error(
        'Assicurati di compilare tutti i campi e selezionare un file.'
      );
    } else {
      LoaderComponent.isLoading = false;
      this.showMessage('Seleziona un file', 'error');
    }
  }

  exportQuiz(id: number[]) {
    this.check = id.map(String);
    LoaderComponent.isLoading = true;
    if (id !== null) {
      this.banchedati.exportQuiz(id).subscribe(
        (response: any) => {
          this.downloadFile(response.body);
          LoaderComponent.isLoading = false;
          // this.selectionService.deselect(item);
          this.selectionService.deselectAll();
          this.updateSelectionAll();
        },
        (error) => {
          this.showMessage(error.error.message, 'error');
        }
      );
    }
  }

  downloadFile(data: Blob) {
    const blob = new Blob([data], { type: 'application/zip' });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'nome_del_file');
    document.body.appendChild(link);
    link.click();

    link.onload = () => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    };
  }

  showModify2(event: MouseEvent) {
    this.modifica2 = !this.modifica2;
  }

  showModifyDetails(
    event: MouseEvent,
    id: number,
    domanda: string,
    rispostaA: string,
    rispostaB: string,
    rispostaC: string,
    rispostaD: string,
    rispostaE: string,
    rispostaF: string,
    corretta: string,
    numerazioneMida: string,
    titoloDiStudio: string,
    anno: string,
    ente: string,
    profilo: string,
    materia: string,
    argomento: string,
    difficolta: number,
    immagine: string,
    immagineSpiegazioneRisposta: string,

    linkPreparazione: string
  ) {
    this.showDomande = !this.showDomande;
    this.idToModify = id;

    this.modalForm.changeForm({
      domanda: domanda,
      rispostaA: rispostaA,
      rispostaB: rispostaB,
      rispostaC: rispostaC,
      rispostaD: rispostaD,
      rispostaE: rispostaE,
      rispostaF: rispostaF,
      rispostaCorretta: corretta,
      numerazioneMida: numerazioneMida,
      titoliStudio: titoloDiStudio,
      anno: anno,
      ente: ente,
      profilo: profilo,
      materia: materia,
      argomento: argomento,
      difficolta: difficolta.toString(),
      immagine: immagine,
      immagineSpiegazioneRisposta: immagineSpiegazioneRisposta,
      linkPreparazione: linkPreparazione,
    });
    this.modalForm.getForm().subscribe((form) => {
      this.contenutoToModify = form.domanda;
      this.rispostaAToModify = form.rispostaA;
      this.rispostaBToModify = form.rispostaB;
      this.rispostaCToModify = form.rispostaC;
      this.rispostaDToModify = form.rispostaD;
      this.rispostaEToModify = form.rispostaE;
      this.rispostaFToModify = form.rispostaF;
      this.correttaToModify = form.rispostaCorretta;
      this.numerazioneMidaToModify = form.numerazioneMida;
      this.titoloDiStudioToModify = form.titoliStudio;
      this.annoToModify = form.anno;
      this.selectedEnteRiga = form.ente;
      this.selectedProfiloRiga = form.profilo;
      this.materiaToModify = form.materia;
      this.argomentoToModify = form.argomento;
      this.difficoltaToModify = form.difficolta;
      this.immagineToModify = form.immagine;
      this.immagineSpiegazioneRispostaToModify = form.immagineSpiegazione;
      this.linkPreparazioneToModify = form.linkPreparazione;
    });
    this.compareFunction();
  }

  compareFunction() {
    this.modalForm
      .getForm()
      .pipe(take(1))
      .subscribe((form) => {
        this.contenutoCheck = form.domanda;
        this.rispostaACheck = form.rispostaA;
        this.rispostaBCheck = form.rispostaB;
        this.rispostaCCheck = form.rispostaC;
        this.rispostaDCheck = form.rispostaD;
        this.rispostaECheck = form.rispostaE;
        this.rispostaFCheck = form.rispostaF;
        this.correttaCheck = form.rispostaCorretta;
        this.numerazioneMidaCheck = form.numerazioneMida;
        this.titoloDiStudioCheck = form.titoliStudio;
        this.annoCheck = form.anno;
        this.enteCheck = form.ente;
        this.profiloCheck = form.profilo;
        this.materiaCheck = form.materia;
        this.argomentoCheck = form.argomento;
        this.difficoltaCheck = form.difficolta;
        this.immagineCheck = form.immagine;
        this.immagineSpiegazioneRispostaCheck = form.immagineSpiegazione;
        this.linkPreparazioneCheck = form.linkPreparazione;
      });
    console.log(this.contenutoCheck);
  }
  modifyDomanda() {
    LoaderComponent.isLoading = true;

    return this.banchedati
      .modifyDomanda(
        this.idToModify,
        this.contenutoToModify === this.contenutoCheck
          ? undefined
          : this.contenutoToModify,
        this.rispostaAToModify === this.rispostaACheck
          ? undefined
          : this.rispostaAToModify,
        this.rispostaBToModify === this.rispostaBCheck
          ? undefined
          : this.rispostaBToModify,
        this.rispostaCToModify === this.rispostaCCheck
          ? undefined
          : this.rispostaCToModify,
        this.rispostaDToModify === this.rispostaDCheck
          ? undefined
          : this.rispostaDToModify,
        this.rispostaEToModify === this.rispostaECheck
          ? undefined
          : this.rispostaEToModify,
        this.rispostaFToModify === this.rispostaFCheck
          ? undefined
          : this.rispostaFToModify,
        this.correttaToModify === this.correttaCheck
          ? undefined
          : this.correttaToModify,
        this.numerazioneMidaToModify === this.numerazioneMidaCheck
          ? undefined
          : this.numerazioneMidaToModify,
        this.titoloDiStudioToModify === this.titoloDiStudioCheck
          ? undefined
          : this.titoloDiStudioToModify,
        this.annoToModify === this.annoCheck ? undefined : this.annoToModify,
        this.selectedEnteRiga === this.enteCheck
          ? undefined
          : this.selectedEnteRiga,
        this.selectedProfiloRiga === this.profiloCheck
          ? undefined
          : this.selectedProfiloRiga,
        this.materiaToModify === this.materiaCheck
          ? undefined
          : this.materiaToModify,
        this.argomentoToModify === this.argomentoCheck
          ? undefined
          : this.argomentoToModify,
        this.difficoltaToModify === this.difficoltaCheck
          ? undefined
          : this.difficoltaToModify,
        this.immagineToModify === this.immagineCheck
          ? undefined
          : this.immagineToModify,
        this.immagineSpiegazioneRispostaToModify ===
          this.immagineSpiegazioneRispostaCheck
          ? undefined
          : this.immagineSpiegazioneRispostaToModify,
        this.linkPreparazioneToModify === this.linkPreparazioneCheck
          ? undefined
          : this.linkPreparazioneToModify
      )
      .subscribe(
        (response: any) => {
          this.changeId.push(this.idToModify);

          this.mappingService.avvia().subscribe();
          this.getAllQuestionsId();
          this.showDomande = !this.showDomande;

          this.modifica2 = !this.modifica2;
        },
        (error: any) => {
          this.showMessage(error.error.message, 'error');
          LoaderComponent.isLoading = false;
          console.error('Errore durante la creazione della materia:', error);
        }
      );
  }

  showDelete(event: MouseEvent) {
    this.elimina = !this.elimina;
    event.stopPropagation();
  }

  deleteQuiz() {
    LoaderComponent.isLoading = true;
    return this.banchedati.deleteQuiz(this.check).subscribe(
      (response: any) => {
        this.getAllQuestionsId();
        this.selectionService.deselectAll();
        this.selectionService.getSelectedIds();
        this.mappingService.avvia().subscribe();
        this.updateSelectionAll();
        this.showSlide = false;
        this.elimina = !this.elimina;
        LoaderComponent.isLoading = false;
      },
      (error: any) => {
        this.showMessage(error.error.message, 'error');
        LoaderComponent.isLoading = false;
      }
    );
  }

  duplicateDomanda() {
    LoaderComponent.isLoading = true;
    return this.banchedati.duplicaQuiz(this.check).subscribe(
      (response: any) => {
        this.getAllQuestionsId();
        this.selectionService.deselectAll();
        this.updateSelectionAll();

        this.showSlide = false;
        LoaderComponent.isLoading = false;
      },
      (error: any) => {
        this.showMessage(error.error.message, 'error');
        LoaderComponent.isLoading = false;
        console.error('Errore ', error);
      }
    );
  }

  onSelectedTitoli(selectedValue: string) {
    if (selectedValue && selectedValue !== 'Seleziona o inserisci l’ente') {
      this.selectedTitoliRiga = selectedValue;
    }
  }

  onSelectedAnnoRiga(selectedValue: string) {
    if (selectedValue && selectedValue !== 'Seleziona o inserisci l’ente') {
      this.selectedAnnoRiga = selectedValue;
    }
  }

  add(event: MouseEvent) {
    this.showInput = !this.showInput;
    this.resetForm2();
    event.stopPropagation();
  }

  resetForm2() {
    const emptyForm = {
      domanda: '',
      rispostaA: '',
      rispostaB: '',
      rispostaC: '',
      rispostaD: '',
      rispostaE: '',
      rispostaF: '',
      rispostaCorretta: '',
      numerazioneMida: '',
      titoliStudio: null,
      anno: null,
      ente: null,
      profilo: null,
      materia: null,
      argomento: null,
      difficolta: '',
      immagine: '',
      immagineSpiegazione: '',
      linkPreparazione: '',
    };
    this.modalForm.changeForm(emptyForm);
  }

  getFormModal() {
    this.subscription.add(
      this.modalForm.getForm().subscribe((form) => {
        this.domanda = form.domanda;
        this.rispostaA = form.rispostaA;
        this.rispostaB = form.rispostaB;
        this.rispostaC = form.rispostaC;
        this.rispostaD = form.rispostaD;
        this.rispostaE = form.rispostaE;
        this.rispostaF = form.rispostaF;
        this.rispostaCorretta = form.rispostaCorretta;
        this.numerazioneMida = form.numerazioneMida;
        this.selectedTitoli = form.titoliStudio;
        this.selectedAnnoRiga = form.anno;
        this.selectedEnteRiga = form.ente;
        this.selectedProfiloRiga = form.profilo;
        this.selectedMateria = form.materia;
        this.selectedArgomento = form.argomento;
        this.difficoltaDomanda = form.difficolta;
        this.immagineRiga = form.immagine;
        this.immagineSpiegazioneRisposta = form.immagineSpiegazione;
        this.linkVideoSpiegazione = form.linkPreparazione;
      })
    );
  }

  aggiungiDomanda() {
    LoaderComponent.isLoading = true;
    this.getFormModal();
    return this.banchedati
      .aggiungiDomandaQuiz(
        this.domanda,
        this.rispostaA,
        this.rispostaB,
        this.rispostaCorretta,
        this.numerazioneMida,
        this.selectedAnnoRiga,
        this.selectedMateria,
        this.immagineRiga,
        this.immagineSpiegazioneRisposta,
        this.linkVideoSpiegazione,
        this.linkPreparazione,
        this.rispostaC,
        this.rispostaD,
        this.rispostaE,
        this.rispostaF,
        this.selectedTitoli,
        this.selectedEnteRiga,
        this.selectedProfiloRiga,
        this.selectedArgomento?.toString(),
        this.difficoltaDomanda
      )
      .subscribe(
        (response: any) => {
          this.showInput = false;

          (this.domanda = ''),
            (this.rispostaA = ''),
            (this.rispostaB = ''),
            (this.rispostaC = ''),
            (this.rispostaD = ''),
            (this.rispostaE = ''),
            (this.rispostaF = ''),
            (this.rispostaCorretta = ''),
            (this.numerazioneMida = ''),
            (this.selectedTitoliRiga = null),
            (this.selectedAnnoRiga = null),
            (this.enteRiga = ''),
            (this.profiloRiga = ''),
            (this.selectedMateriaRiga = null),
            (this.selectedArgomentoRiga = null),
            (this.difficoltaDomanda = 0),
            (this.immagineRiga = ''),
            (this.immagineSpiegazioneRisposta = ''),
            (this.linkVideoSpiegazione = ''),
            (this.linkPreparazione = '');
          this.mappingService.avvia().subscribe();
          this.getAllQuestionsId();
          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          if (error.status === 409) {
            this.showMessage(error.error.message, 'error');
            LoaderComponent.isLoading = false;
            this.numerazioneMida = '';
          }
          if (error.status === 400) {
            this.showMessage('Compilare i campi correttamente', 'error');
            LoaderComponent.isLoading = false;
          }
        }
      );
  }

  log() {
    console.log('log');
  }
  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }
  erroreForm: any;
  showToastForm: any;
  form() {
    this.subscription.add(
      this.formService.getError().subscribe((error) => {
        this.erroreForm = error;
      })
    );

    this.subscription.add(
      this.formService.currentForm.subscribe((form) => {
        this.titolo = form?.titolo;
        this.descrizioneModal = form?.descrizione;
        this.selectedEnte = form?.ente;
        this.selectedProfilo = form?.profilo;
        this.selectedRegione = form?.regione;
        this.selectedAnno = form?.anno;
      })
    );
  }

  chiudiCofirm() {
    this.formService.setConfirm(false);
    this.selectionService.deselectAll();

    this.selectionService.updateCheck(false);
    this.updateSelectionAll();
  }
}
