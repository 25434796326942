import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faBars, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { TranslocoService } from '@ngneat/transloco';
import { Breadcrumb } from '@stdoutsrl/ui-kit';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BancheDatiComponent } from 'src/app/pages/banche-dati/banche-dati.component';
import { StatoUtenteService } from '@services/stato-utente.service';
import { set } from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string = '';
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() titleProject = false;
  showSidenav: boolean = false;
  phone: boolean = false;
  route: string | undefined;
  @Input() endpoint = '';
  nome: string = '';
  // icons
  faBars = faBars;
  faGlass = faMagnifyingGlass;
  constructor(
    private statoUtente: StatoUtenteService,
    private Route: ActivatedRoute,
    location: Location,
    router: Router,
    private transloco: TranslocoService
  ) {
    {
      this.Route.queryParams.subscribe((param: any) => {
        this.nome = decodeURIComponent(param.nome);
      });
    }

    router.events.subscribe((val) => {
      if (location.path() != '') {
        this.transloco
          .selectTranslation(localStorage.getItem('translocoLang') ?? 'it')
          .subscribe({
            next: (translation: any) => {
              this.route = location.path();
              if (this.route == '') {
                this.endpoint = 'Home';
                this.breadcrumbs = [];
              }
              if (this.route == '/pages/anagrafiche/customers') {
                this.endpoint = 'clienti';
                this.breadcrumbs = [
                  {
                    url: '/pages/anagrafiche/customers',
                    label: translation['clienti'],
                  },
                ];
              }
              if (this.route == '/pages/anagrafiche/providers') {
                this.endpoint = 'fornitori';
                this.breadcrumbs = [
                  {
                    url: '/pages/anagrafiche/providers',
                    label: translation['fornitori'],
                  },
                ];
              }

              if (this.route == '/consigliati') {
                this.endpoint = 'Consigliati';
                this.breadcrumbs = [
                  {
                    url: '/consigliati',
                    label: 'Consigliati',
                  },
                ];
              }
              if (this.route == '/banche_dati') {
                this.endpoint = 'Banche Dati';
                this.breadcrumbs = [
                  {
                    url: '/banche_dati',
                    label: 'Banche Dati',
                  },
                ];
              }
              if (this.route == '/utenti') {
                this.endpoint = 'Utenti';
                this.breadcrumbs = [
                  {
                    url: '/utenti',
                    label: 'utenti',
                  },
                ];
              }
              if (this.route == '/Abbonamenti') {
                this.endpoint = 'Abbonamenti';
                this.breadcrumbs = [
                  {
                    url: '/Abbonamenti',
                    label: 'Abbonamenti',
                  },
                ];
              }

              if (this.route == '/quiz') {
                this.endpoint = 'quiz';
                this.breadcrumbs = [
                  {
                    url: '/quiz',
                    label: 'quiz',
                  },
                ];
              }
              if (this.route == '/notifiche') {
                this.endpoint = 'notifiche';
                this.breadcrumbs = [
                  {
                    url: '/notifiche',
                    label: 'notifiche',
                  },
                ];
              }
              if (this.route == '/funzionalita') {
                this.endpoint = 'funzionalità';
                this.breadcrumbs = [
                  {
                    url: '/funzionalita',
                    label: 'funzionalità',
                  },
                ];
              }
              if (this.route == '/utenti') {
                this.endpoint = 'utenti';
                this.breadcrumbs = [
                  {
                    url: '/utenti',
                    label: 'utenti',
                  },
                ];
              }
              if (this.route == '/user') {
                this.endpoint = 'Account Personale';
                this.breadcrumbs = [
                  {
                    url: '/user',
                    label: 'Account Personale',
                  },
                ];
              }
              if (this.route == '/collaboratori') {
                this.endpoint = 'collaboratori';
                this.breadcrumbs = [
                  {
                    url: '/collaboratori',
                    label: 'collaboratori',
                  },
                ];
              }
              if (this.route == '/enti') {
                this.endpoint = 'enti';
                this.breadcrumbs = [
                  {
                    url: '/enti',
                    label: 'enti',
                  },
                ];
              }
              if (this.route == '/profili') {
                this.endpoint = 'profili';
                this.breadcrumbs = [
                  {
                    url: '/profili',
                    label: 'profili',
                  },
                ];
              }
              if (this.route == '/anni') {
                this.endpoint = 'anni';
                this.breadcrumbs = [
                  {
                    url: '/anni',
                    label: 'anni',
                  },
                ];
              }
              if (this.route.includes('/bancadati')) {
                this.endpoint = this.nome;
                this.breadcrumbs = [
                  {
                    url: '/banche_dati',
                    label: 'Banche Dati > ' + this.nome,
                  },
                ];
              }
            },

            complete: () => {},
          });
      } else {
        this.endpoint = 'Home';
      }
    });
  }
  isLoggin: boolean = false;

  ngOnInit(): void {
    this.route = location.pathname;

    window.innerWidth < 700 ? (this.phone = true) : (this.phone = false);
  }

  onResize(event: any) {
    event.target.innerWidth < 700 ? (this.phone = true) : (this.phone = false);
  }
}
