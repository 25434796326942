<app-header></app-header>

<div [class.opacity-50]="
    import == true || loader || modifica2 || showDomande || elimina || showInput || confirm
  " [class.non-clickable]="
    import == true || loader || modifica2 || showDomande || elimina || showInput || confirm
  " class="w-full h-full mb-[30vh]" [ngClass]="{ 'overflow-hidden': !showFilter }"
  [ngClass]="{ 'mb-[30vh]': showFilter }">
  <div class="flex margine justify-between items-center mx-10">
    <div class="flex items-center w-1/2">
      <div class="flex input-container justify-start items-center" *ngIf="!isPhone">
        <stdout-icon-button class="lente items-center  cursor-pointer" style="margin: 2px 10px 0px 0px" size="small"
          background="secondary" color="white" borderColor="secondary" [icon]="faMagnifyingGlass">
        </stdout-icon-button>
        <div class="w-[300px]">
          <stdout-text-input class="search-bar" placeholder="Cerca quiz" [(ngModel)]="search"
            (keyup)="applyFilter()"></stdout-text-input>
        </div>
      </div>
    </div>
    <!-- parte destra -->
    <div class="flex lg:flex-row flex-col lg:gap-0 gap-5 lg:items-center lg:justify-end  w-2/3 ">

      <div class=" flex items-center w-full justify-end relative  mr-5">
        <p *ngIf="!showVisual" class="text-[#0B0E42] text-right font-montserrat text-base font-medium leading-normal">
          <b>Ordine Visualizzazione </b>
        </p>

        <p *ngIf="showVisual" class="text-[#0B0E42] text-right font-montserrat text-base font-medium leading-normal">
          <b>Chiudi </b>
        </p>
        <div class="ml-[15px]  cursor-pointer" (click)="$event.stopPropagation()">
          <stdout-icon-button class="lente" (click)="openVisual()" [icon]="ordinaPer === 2 ? (ordinaDesc ? faArrowDownZA : faArrowUpAZ) :
          ordinaPer === 1 ? (ordinaDesc ? faArrowDown91 : faArrowUp19) :
          ordinaPer === 0 && !ordinaDesc ? faSort : faSort"></stdout-icon-button>
        </div>
        <!-- dropdown ordinamento -->
        <div class="absolute z-50 element min-h-700  top-full mt-5 w-[300px] bg-white rounded-[15px] p-[25px]"
          *ngIf="showVisual" (click)="$event.stopPropagation()">
          <div class="flex flex-col gap-4 items-center justify-center">
            <div class="md:max-w-[200px] w-full mt-5 md:mt-0">
              <div class=" w-full  cursor-pointer">

                <!-- <stdout-icon-button class="lente-bianca" (click)="ordinaAlfabetico()" [icon]="faD"></stdout-icon-button> -->
                <stdout-button (click)="ordinaAlfabetico()" size="large" color="black"
                  [class]="ordinaPer==2 ? 'btn-blu' : 'btn-bianco' ">
                  <h2>{{ "ordine_alf" | transloco }}</h2>
                </stdout-button>
              </div>
            </div>
            <div class="md:max-w-[200px] w-full mt-5 md:mt-0">
              <div class=" w-full  cursor-pointer">

                <!-- <stdout-icon-button class="lente-bianca" (click)="ordinaNumerico()" [icon]="faN"></stdout-icon-button> -->
                <stdout-button (click)="ordinaNumerico()" size="large" color="black"
                  [class]="ordinaPer==1 ? 'btn-blu' : 'btn-bianco' ">
                  <h2>{{ "ordine_num" | transloco }}</h2>
                </stdout-button>
              </div>
            </div>
            <div class="md:max-w-[200px] w-full mt-5 md:mt-0">
              <div class=" w-full  cursor-pointer">

                <!-- <stdout-icon-button class="lente-bianca" (click)="ordinaNumerico()" [icon]="faN"></stdout-icon-button> -->
                <stdout-button (click)="ordinaIndice()" size="large" color="black"
                  [class]="ordinaPer==3 ? 'btn-blu' : 'btn-bianco' ">
                  <h2>{{ "ordine_ind" | transloco }}</h2>
                </stdout-button>
              </div>
            </div>
            <div class="md:max-w-[200px] w-full mt-5 md:mt-0">
              <div class=" w-full  cursor-pointer">

                <stdout-button (click)="invertiOrdine()" size="large" color="black"
                  [class]="ordinaDesc===true ? 'btn-blu' : 'btn-bianco' ">
                  <h2>{{ "inverti_ord" | transloco }}</h2>
                </stdout-button>
              </div>
            </div>

            <div class="w-full h-full flex items-center justify-center">
              <div class="flex input-container justify-center items-center mt-4 md:max-w-[200px]" *ngIf="!isPhone">
                <stdout-icon-button class="lente items-center  cursor-pointer" style="margin: 2px 10px 0px 0px"
                  size="small" background="secondary" color="white" borderColor="secondary" [icon]="faMagnifyingGlass">
                </stdout-icon-button>
                <div class="">
                  <stdout-text-input type="number" class="search-bar" placeholder="vai a indice" [(ngModel)]="index"
                    (keyup)="applyIndex($event)"></stdout-text-input>
                </div>
              </div>
            </div>
            <div class="w-full h-full flex items-center justify-center mt-4">
              <div class="md:max-w-[200px] w-full md:mt-0">
                <div class=" w-full  cursor-pointer">

                  <stdout-button class="btn-bianco" (click)="resetOrdine()" size="large" color="black">
                    <h2>{{ "resetta" | transloco }}</h2>
                  </stdout-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" flex items-center w-full justify-end relative">
        <p *ngIf="!showFilter" class="text-[#0B0E42] text-right font-montserrat text-base font-medium leading-normal">
          <b>Filtri </b>
        </p>

        <p *ngIf="showFilter" class="text-[#0B0E42] text-right font-montserrat text-base font-medium leading-normal">
          <b>Chiudi filtri</b>
        </p>
        <div class="ml-[15px]  cursor-pointer" (click)="$event.stopPropagation()">
          <stdout-icon-button class="lente" (click)="openFilter()"
            [icon]="showFilter ? faXmark : faFilter"></stdout-icon-button>
        </div>
        <!-- dropdown -->
        <div class="absolute z-50 element min-h-700 top-full mt-5 w-[300px] bg-white rounded-[15px] p-[25px]"
          *ngIf="showFilter" (click)="$event.stopPropagation()">
          <!-- titolo studio -->
          <div class="flex flex-col w-full h-full justify-start items-center">
            <div class="flex justify-start items-center w-full h-full">
              <div class="mr-[5px]">
                <p class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal">
                  {{ "Tit_stud" | transloco }}
                </p>
              </div>
              <div>
                <fa-icon (click)="show1 = !show1" [icon]="show1 ? faChevronUp : faChevronDown"></fa-icon>
              </div>
            </div>
            <div class="w-full h-full mt-[10px]" *ngIf="show1">
              <stdout-select class="fix-select" id="select1" [placeholder]="'Seleziona o inserisci l argomento'"
                [clearable]="false" [data]="listaTitoliStudio" type="text"
                (onSelectedItem)="addSelectedTitoli($event.event)" [value]="selectedTitoli?.nome" bindLabel="nome"
                bindValue="nome" #selectElement>
              </stdout-select>
            </div>
          </div>
          <!-- anno -->
          <div class="flex flex-col w-full h-full justify-start items-center mt-5">
            <div class="flex justify-start items-center w-full h-full">
              <div class="mr-[5px]">
                <p class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal">
                  {{ "anno" | transloco }}
                </p>
              </div>
              <div>
                <fa-icon (click)="show2 = !show2" [icon]="show2 ? faChevronUp : faChevronDown"></fa-icon>
              </div>
            </div>
            <div class="w-full h-full mt-[10px]" *ngIf="show2">
              <stdout-select class="fix-select" id="select1" placeholder="Seleziona o inserisci l'anno"
                [clearable]="false" (onSelectedItem)="addSelectedAnni($event.event)" [data]="combinedAnno"
                [value]="selectedAnno" placeholder="Seleziona l’anno " #selectElement>
              </stdout-select>
            </div>
          </div>
          <!-- ente -->
          <div class="flex flex-col w-full h-full justify-start items-center mt-5">
            <div class="flex justify-start items-center w-full h-full">
              <div class="mr-[5px]">
                <p class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal">
                  {{ "ente" | transloco }}
                </p>
              </div>
              <div>
                <fa-icon (click)="show3 = !show3" [icon]="show3 ? faChevronUp : faChevronDown"></fa-icon>
              </div>
            </div>
            <div class="w-full h-full mt-[10px]" *ngIf="show3">
              <stdout-select class="fix-select" placeholder="Seleziona ente" id="select1" type="text"
                [clearable]="false" (onSelectedItem)="addSelectedEnte($event.event)" [value]="selectedEnte?.nome"
                [data]="listaEnti" bindLabel="nome" bindValue="nome" #selectElement>
              </stdout-select>
            </div>
          </div>
          <!-- profilo -->
          <div class="flex flex-col w-full h-full justify-start items-center mt-5">
            <div class="flex justify-start items-center w-full h-full">
              <div class="mr-[5px]">
                <p class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal">
                  {{ "profilo" | transloco }}
                </p>
              </div>
              <div>
                <fa-icon (click)="show4 = !show4" [icon]="show4 ? faChevronUp : faChevronDown"></fa-icon>
              </div>
            </div>
            <div class="w-full h-full mt-[10px]" *ngIf="show4">
              <stdout-select class="fix-select" id="select1" [placeholder]="'Seleziona o inserisci il profilo'"
                [clearable]="false" [data]="listaProfili" type="text"
                (onSelectedItem)="addSelectedProfili($event.event)" [value]="selectedProfilo?.nome" bindLabel="nome"
                bindValue="nome" #selectElement>
              </stdout-select>
            </div>
          </div>
          <!-- materia -->
          <div class="flex flex-col w-full h-full justify-start items-center mt-5">
            <div class="flex justify-start items-center w-full h-full">
              <div class="mr-[5px]">
                <p class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal">
                  {{ "materia" | transloco }}
                </p>
              </div>
              <div>
                <fa-icon (click)="show5 = !show5" [icon]="show5 ? faChevronUp : faChevronDown"></fa-icon>
              </div>
            </div>
            <div class="w-full h-full mt-[10px]" *ngIf="show5">
              <stdout-select class="fix-select" id="select1" [placeholder]="'Seleziona o inserisci la materia'"
                [clearable]="false" [data]="listaMaterie" type="text"
                (onSelectedItem)="addSelectedMaterie($event.event)" [value]="selectedMateria?.nome" bindLabel="nome"
                bindValue="nome" #selectElement>
              </stdout-select>
            </div>
          </div>
          <!-- argomento -->
          <div class="flex flex-col w-full h-full justify-start items-center mt-5">
            <div class="flex justify-start items-center w-full h-full">
              <div class="mr-[5px]">
                <p class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal">
                  {{ "argomento" | transloco }}
                </p>
              </div>
              <div>
                <fa-icon (click)="show6 = !show6" [icon]="show6 ? faChevronUp : faChevronDown"></fa-icon>
              </div>
            </div>
            <div class="w-full h-full mt-[10px]" *ngIf="show6">
              <stdout-select class="fix-select" id="select1" [placeholder]="'Seleziona o inserisci l argomento'"
                [clearable]="false" [data]="listaArgomenti" type="text"
                (onSelectedItem)="addSelectedArgomenti($event.event)" [value]="selectedArgomento?.nome" bindLabel="nome"
                bindValue="nome" #selectElement>
              </stdout-select>
            </div>
          </div>
          <!--difficolta -->
          <div class="flex flex-col w-full h-full justify-start items-center mt-5">
            <div class="flex justify-start items-center w-full h-full">
              <div class="mr-[5px]">
                <p class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal">
                  {{ "difficolta" | transloco }}
                </p>
              </div>
              <div>
                <fa-icon (click)="show7 = !show7" [icon]="show7 ? faChevronUp : faChevronDown"></fa-icon>
              </div>
            </div>
            <div class="w-full h-full mt-[10px]" *ngIf="show7">
              <stdout-select class="fix-select" id="select1" [placeholder]="'Seleziona o inserisci l argomento'"
                [clearable]="false" [data]="listaDifficolta" type="text"
                (onSelectedItem)="addSelectedDifficolta($event.event)" [value]="selectedDifficolta?.nome"
                bindLabel="nome" bindValue="nome" #selectElement>
              </stdout-select>
            </div>
          </div>
          <!--immagini -->
          <div class="flex flex-col w-full h-full justify-start items-center mt-5">
            <div class="flex justify-start items-center w-full h-full">
              <div class="mr-[5px]">
                <p class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal">
                  {{ "immagini" | transloco }}
                </p>
              </div>
              <div>
                <fa-icon (click)="show8 = !show8" [icon]="show8 ? faChevronUp : faChevronDown"></fa-icon>
              </div>
            </div>
            <div class="w-full h-full mt-[10px]" *ngIf="show8">
              <div class="flex items-center justify-start mt-5">
                <div class="mr-[10px]">
                  <app-switch [isChecked]="initialValue" (change)="handleSwitchChange($event)"></app-switch>
                </div>
                <div>
                  <p class="text-[#0B0E42] font-montserrat text-sm font-normal leading-normal">
                    mostra immagini
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- switch -->
          <div class="flex items-center justify-start mt-5">
            <div class="mr-[10px]">
              <app-switch [isChecked]="initialValue" (change)="handleSwitchChange($event)"></app-switch>
            </div>
            <div>
              <p class="text-[#0B0E42] font-montserrat text-sm font-normal leading-normal">
                mostra domande uguali
              </p>
            </div>
          </div>
          <!-- bottoni -->
          <div class="flex justify-between items-center margine">
            <div class="w-[48.5%]">
              <stdout-button class="btn-bianco" (click)="resetta_filtro()" size="large" color="black">
                <h2>{{ "resetta" | transloco }}</h2>
              </stdout-button>
            </div>
            <div class="w-[48.5%]">
              <stdout-button class="btn-blu" (click)="aggiungi()" size="large" color="black">
                <h2>{{ "aggiungi" | transloco }}</h2>
              </stdout-button>
            </div>
          </div>
        </div>

      </div>




    </div>
  </div>

  <!-- sezione filtri_visualizzazione -->
  <div class="mx-[30px] mt-[30px] flex items-baseline flex-wrap" *ngIf="filtri.length > 0">
    <div class="filtro flex mx-[10px] mt-[10px]" *ngFor="let filtro of filtri; let i = index">
      <div class="flex items-center">
        <p>
          {{ filtro.anno }}
          <ng-container *ngIf="filtro.anno?.length > 0 && filtro.ente?.length > 0">/</ng-container>{{ filtro.ente }}
          <ng-container *ngIf="filtro.ente?.length > 0 && filtro.materia?.length > 0">/</ng-container>{{ filtro.materia
          }}
          <ng-container *ngIf="filtro.materia?.length > 0 && filtro.profilo?.length > 0">/</ng-container>{{
          filtro.profilo }}
          <ng-container *ngIf="filtro.profilo?.length > 0 && filtro.argomento?.length > 0">/</ng-container>{{
          filtro.argomento }}
          <ng-container *ngIf="filtro.argomento?.length > 0 && filtro.difficolta?.length > 0">/</ng-container>{{
          filtro.difficolta }}
          <ng-container *ngIf="filtro.difficolta?.length > 0 && filtro.titoli?.length > 0">/</ng-container>{{
          filtro.titoli }}
        </p>
      </div>
      <div class="icona-fil">
        <fa-icon [icon]="faXmark" (click)="rimuoviElemento(i)"></fa-icon>
      </div>
    </div>
    <div class="ml-[10px] max-w-[131px] h-[33px]  cursor-pointer">
      <stdout-button class="btn-bianco" (click)="reset()" size="large" color="black">
        <h2>{{ "resetta" | transloco }}</h2>
      </stdout-button>
    </div>
  </div>
  <div class="margine flex items-center justify-between flex-col md:flex-row px-10">
    <div class="w-full">
      <div class="flex w-full gap-[15px] flex-col md:flex-row">
        <!-- <div class=" md:max-w-[231px]   cursor-pointer  w-full">
    <stdout-button class="btn-bianco  "  size="large" color="black" ><h2>{{"importa_daLib"|transloco}}</h2></stdout-button>
</div> -->
        <div class="md:max-w-[188px]  cursor-pointer w-full">
          <input type="file" #fileInput (change)="onFileSelected($event)" accept=".xlsx" style="display: none" />
          <stdout-button class="btn-bianco" (click)="onButtonClick($event)" size="large" color="black">
            <h2>{{ "importa_daEst" | transloco }}</h2>
          </stdout-button>
        </div>
        <div class="md:max-w-[119px] max-w-max- w-full mt-5 md:mt-0  cursor-pointer">
          <stdout-button class="btn-bianco" size="large" color="black" [disabled]="numOfQuizSelected() === 0"
            [class.opacity-50]="numOfQuizSelected() === 0" (click)="exportQuiz(domandeIds)">
            <h2>{{ "esporta" | transloco }}</h2>
          </stdout-button>
        </div>
        <div class="md:max-w-[200px] w-full mt-5 md:mt-0">
          <div class="md:max-w-[45px] w-full  cursor-pointer">
            <!-- <stdout-button class="btn-bianco  " size="large" color="black" (click)="add($event)" ><h2>{{"aggiungi riga"|transloco}}</h2></stdout-button> -->

            <stdout-icon-button class="lente-bianca" (click)="add($event)" [icon]="faCirclePlus"></stdout-icon-button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-5 md:mt-0 w-full items-center flex-col md:flex-row justify-end">
      <div class="w-full flex items-center gap-[20px] justify-end">
        <div class="w-full md:max-w-[157px] flex justify-end">
          <div>
            <p class="whitespace-nowrap">
              {{ numOfQuizSelected() }} {{ "quiz_selezionati" | transloco }}
            </p>
          </div>
        </div>

        <div class="flex items-center justify-end">
          <div class="w-full md:max-w-[150px]">
            <stdout-button [ngClass]="
                showSlide === false
                  ? 'btn-bianco'
                  : 'btn-blu  cursor-pointer'
              " class="w-full" [disabled]="numOfQuizSelected() === 0" [class.opacity-50]="numOfQuizSelected() === 0"
              (click)="showSlide = !showSlide" size="large" color="black">
              <h2>{{ "modifica" | transloco }}</h2>
            </stdout-button>
          </div>

          <div class="flex justify-between items-center flex-col md:flex-row" *ngIf="showSlide">
            <div class="mx-[10px]  cursor-pointer">
              <stdout-icon-button class="bottoni-icon" (click)="duplicateDomanda()" color="rgba(11,14,66,1)"
                [icon]="faCopy"></stdout-icon-button>
            </div>
            <div class=" cursor-pointer">
              <stdout-icon-button class="bottoni-icon" (click)="showDelete($event)" color="rgba(11,14,66,1)"
                [icon]="faTrash"></stdout-icon-button>
            </div>
          </div>
        </div>
        <div class="w-full md:max-w-[300px]  cursor-pointer">
          <stdout-button class="btn-bianco" [disabled]="numOfQuizSelected() === 0"
            [class.opacity-50]="numOfQuizSelected() === 0" (click)="importaBanca($event)" size="large" color="black">
            <h2>{{ "crea_banca" | transloco }}</h2>
          </stdout-button>
        </div>
      </div>
    </div>
  </div>


  <!-- <div
        class="scrollable-container px-10 mt-5 h-full"
        #scrollingDiv
        (scroll)="onScroll($event)"
      > -->
  <div class="w-full px-10 my-5 control  " *ngIf="listaQuiz.length>0">
    <app-tabella-def [totalItemExisting]="listaId.length" (loadMore)="getNextPaginatedQuestions()" (loadMoreBefore)="getBeforePaginatedQuestions()"
      [overstack]="true" [totalElement]="totalPages" [titles]="titles" [showCheckbox]="true" [data]="listaQuiz"
      [checkboxHeaderClass]="checkHeader" [checkboxBody]="checkBody" [tabText]="tabText" [headerText]="headerText"
      tableContainerClass="show-bar" (selectionChangeAll)="updateSelectionAll()" [myCheck]="check"
      (selectionChange)="updateSelection($event)" (clickedItem)="
            showModifyDetails(
              $event,
              $event.id,
              $event.domanda,
              $event.rispostaA,
              $event.rispostaB,
              $event.rispostaC,
              $event.rispostaD,
              $event.rispostaE,
              $event.rispostaF,
              $event.correttaTag,
              $event.numerazioneMida,
              $event.titoloStudio,
              $event.anno,
              $event.ente,
              $event.profilo,
              $event.materia,
              $event.argomento,
              $event.difficolta,
              $event.immagine,
              $event.immagineSpiegazioneRisposta,
              $event.linkPreparazione
            )
          
          ">
    </app-tabella-def>
  </div>
</div>

<!-- </div> -->

<!-- modale crea -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"
  *ngIf="import" [class.opacity-20]="loader === true">
  <div class="   m-auto w-full  ">
    <div class="my-10 mx-auto w-[77.352%]  shadow-2xl ">

      <app-modale-crea (click)="$event.stopPropagation()" [nomiEnti]="nomiEnti" [nomiProfili]="nomiProfili"
        [combinedAnno]="combinedAnno" [combinedEnti]="combinedEnti" [combinedProfili]="combinedProfili"
        [listaRegioni]="listaRegioni" [Anno]="Anno" [id]="idCreate" [selectedFile]="selectedFile">
      </app-modale-crea>
    </div>
  </div>
</div>

<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"
  *ngIf="showModal" [class.opacity-20]="loader === true">
  <div class="max-w-3xl   m-auto w-full  ">
    <div class="my-10 mx-auto flex items-center justify-center  shadow-2xl  max-h-[80vh] media rounded-xl ">
      <div class=" flex items-center justify-center">
        <app-image-cropper (click)="$event.stopPropagation()" class="z-10 bg-white rounded-xl shadow-2xl" [width]="50"
          [height]="50"></app-image-cropper>
      </div>
    </div>
  </div>
</div>


<app-loader> </app-loader>


<!-- modale modifica 2 -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[3000] bg-black/40"
  *ngIf="modifica2" [class.opacity-20]="loader === true">
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">

      <app-modale (click)="$event.stopPropagation()" title="Conferma il salvataggio"
        content="Sei sicuro di volere salvare le modifiche effettuate?" [width]="386">
        <div class="flex justify-center items-center mt-10">
          <div class="w-full mr-[5px]  cursor-pointer">
            <stdout-button class="btn-bianco" (click)="showModify2($event)" size="large" color="white">
              <h2>{{ "chiudi" | transloco }}</h2>
            </stdout-button>
          </div>
          <div class="w-full ml-[5px]  cursor-pointer">
            <stdout-button class="btn-blu" (click)="modifyDomanda()" size="large" color="white">
              <h2>{{ "salva" | transloco }}</h2>
            </stdout-button>
          </div>
        </div>
      </app-modale>
    </div>
  </div>
</div>

<!-- modale modifica riga -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"
  *ngIf="showDomande" [class.opacity-20]="loader === true" [class.opacity-50]="modifica2 === true">
  <div class=" m-auto  ">
    <div class="my-10 mx-auto     ">

      <app-modal-form (click)="$event.stopPropagation()" [titolo]="'Modifica Riga'" [combinedAnno]="combinedAnno"
        [nomiEnti]="nomiEnti" [nomiProfili]="nomiProfili" [combinedEnti]="combinedEnti"
        [combinedTitoli]="combinedTitoli" [combinedProfili]="combinedProfili" [combinedMaterie]="combinedMaterie"
        [combinedArgomenti]="combinedArgomenti" [listaRegioni]="listaRegioni" [Anno]="Anno"
        [listaRegioni]="listaRegioni" [listaDifficolta]="listaDifficolta" [listaMaterie]="listaMaterie"
        [listaArgomenti]="listaArgomenti" [listaTitoliStudio]="listaTitoliStudio" [listaProfili]="listaProfili"
        [listaEnti]="listaEnti" (onSubmit)="showModify2($event)"></app-modal-form>
    </div>
  </div>
</div>

<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"
  *ngIf="elimina" [class.opacity-20]="loader === true">
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">

      <app-modale (click)="$event.stopPropagation()" title="Conferma eliminazione quiz"
        content="Sei sicuro di volere eliminare definitivamente gli elementi selezionati?" [width]="504">
        <div class="flex justify-center items-center mt-10">
          <div class="w-full mr-[5px]  cursor-pointer">
            <stdout-button class="btn-bianco" (click)="showDelete($event)" size="large" color="white">
              <h2>{{ "annulla" | transloco }}</h2>
            </stdout-button>
          </div>
          <div class="w-full ml-[5px]  cursor-pointer">
            <stdout-button class="btn-blu" (click)="deleteQuiz()" size="large" color="white">
              <h2>{{ "elimina_selezionati" | transloco }}</h2>
            </stdout-button>
          </div>
        </div>
      </app-modale>
    </div>
  </div>
</div>



<!-- modale aggiungi riga -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"
  *ngIf="showInput" [class.opacity-20]="loader === true">
  <div class=" m-auto  ">
    <div class="my-10 mx-auto     ">

      <app-modal-form (click)="$event.stopPropagation()" [titolo]="'Aggiungi Riga'" [combinedAnno]="combinedAnno"
        [nomiEnti]="nomiEnti" [nomiProfili]="nomiProfili" [combinedEnti]="combinedEnti"
        [combinedTitoli]="combinedTitoli" [combinedProfili]="combinedProfili" [listaRegioni]="listaRegioni"
        [combinedMaterie]="combinedMaterie" [combinedArgomenti]="combinedArgomenti" [Anno]="Anno"
        [listaRegioni]="listaRegioni" [listaDifficolta]="listaDifficolta" [listaMaterie]="listaMaterie"
        [listaArgomenti]="listaArgomenti" [listaTitoliStudio]="listaTitoliStudio" [listaProfili]="listaProfili"
        [listaEnti]="listaEnti" (onSubmit)="aggiungiDomanda()"></app-modal-form>
    </div>
  </div>
</div>

<div class="absolute top-[5%] w-[88.489%] flex justify-center items-center">
  <div class="w-fit h-full">
    <app-toast colore="#DF4343" [testo]="erroreForm" [showToast]="showToastForm"></app-toast>
  </div>
</div>


<!-- modale conferma import  -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"
  *ngIf="confirm" [class.opacity-20]="loader === true">
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">


      <app-modale (click)="$event.stopPropagation()" title="Nuova banca dati creata"
        content="Banca Dati creata correttamente" [width]="386">
        <div class="flex justify-center items-center mt-10">

          <div class="w-[200px] ml-[5px]  cursor-pointer">
            <stdout-button class="btn-blu" (click)="chiudiCofirm()" size="large" color="white">
              <h2>{{ "chiudi" | transloco }}</h2>
            </stdout-button>
          </div>
        </div>
      </app-modale>
    </div>
  </div>
</div>