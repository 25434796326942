import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { SelectionService } from '@services/selection.service';
import { ceil, set } from 'lodash';
import { Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'app-tabella-def',
  templateUrl: './tabella-def.component.html',
  styleUrls: ['./tabella-def.component.scss'],
})
export class TabellaDefComponent implements OnInit {
  @Input() showCheckbox = false;
  @Input() myCheck: any[] = [];
  @Input() totalElement: number = 999999;
  @Input() totalItemExisting: number = 0;
  @Input() overstack = false; //serve a staccare l'header dal contenuto
  @Input() data: any; //i dati che vengono inseriti nella tabella
  @Input() tableContainerClass!: string;
  @Input() checkboxContainerClass!: string;
  @Input() checkboxBody!: string;
  @Input() tabText!: string;
  @Input() headerText!: string;
  @Input() checkboxHeaderClass!: string;
  @Input() titles: any; //mandare un'array di oggetti con titolo e chiave {title:'domanda',value:'domanda'},title è il titolo nell'header e invece value è la proprietà presa da data
  @Input() id: string = 'base'; //id della tabella che da l'id pure ai sotto elementi, metterne uno diverso per ogni tabella in caso di più tabelle
  @Input() headerClass: string = '';
  @Output() clickedItem: EventEmitter<any> = new EventEmitter(); //quando si clicca su un elemento della tabella due volte si emette l'evento con l'item cliccato
  @Output() loadMore: EventEmitter<any> = new EventEmitter();
  @Output() loadMoreBefore: EventEmitter<any> = new EventEmitter();
  isScrollable: boolean = true;
  lastLoadedIndex = 0;
  displayedItems = [];
  indexView: number = 0;
  displayCount = 0;
  private subscription: Subscription = new Subscription();
  constructor(private selectionService: SelectionService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.selectionService.getDisplayCount().subscribe((value) => {
        this.displayCount = value;
      })
    );
    this.selectionService
      .getIndexView()
      .pipe(debounceTime(300))
      .subscribe((index) => {
        this.indexView = index;
        this.scrollToIndex(index);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // check: boolean = false;
  // check$ = this.selectionService.check$;
  @Output() selectionChangeAll = new EventEmitter<any[]>();
  @Output() selectionChange = new EventEmitter<any[]>();
  checkAll() {
    console.log(this.myCheck, this.totalItemExisting, this.data);

    if (this.myCheck.length !== this.totalItemExisting) {
      console.log('metti tutti');
      this.selectionService.deselectAll();
      this.selectionService.selectAll(this.data);
      this.data.forEach((item: any) => {
        this.selectionChange.emit(item);
      });
    } else {
      console.log('leva tutti');
      this.selectionService.deselectAll();
      this.data.forEach((item: any) => {
        this.selectionChange.emit(item);
      });
    }
    // this.check = this.myCheck.length == this.totalItemExisting; // Aggiorna this.check qui

    const selectedIds = this.selectionService.getSelectedIds();
    this.selectionChangeAll.emit(selectedIds);
  }

  onCheckboxChange(item: any, event: any) {
    const checked = event.target.checked;

    if (checked) {
      this.selectionService.select(item);
    } else {
      this.selectionService.deselect(item);
    }
    // this.selectionChange.emit(this.selectionService.getSelectedIds());
    this.selectionChange.emit(item.id);
  }
  scrollContent(e: any) {
    let content = document.getElementById(this.id + '-table-header');
    let contentCheck = document.getElementById(this.id + '-checkbox-container');
    if (content) {
      content.scrollLeft = e.target.scrollLeft;
    }
    if (contentCheck) {
      contentCheck.scrollTop = e.target.scrollTop;
    }
    this.onScroll(e);
  }
  scrollHeader(e: any) {
    let content = document.getElementById(this.id + '-table-content');
    if (content) {
      content.scrollLeft = e.target.scrollLeft;
    }
  }
  scrollCheckbox(e: any) {
    let content = document.getElementById(this.id + '-table-content');
    if (content) {
      content.scrollTop = e.target.scrollTop;
    }
  }

  scrollLeft: number = 0; // Define the type for scrollLeft

  mouseDown: boolean = false;
  startX: number = 0; // Define the type for startX

  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;

  startDragging(e: MouseEvent, slider: HTMLElement): void {
    this.mouseDown = true;
    this.startX = e.pageX - slider.offsetLeft;
    this.scrollLeft = slider.scrollLeft;
    this.mouseDown = true;
  }

  stopDragging(): void {
    this.mouseDown = false;
  }

  moveEvent(e: MouseEvent, slider: HTMLElement): void {
    if (!slider) {
      return;
    }

    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - slider.offsetLeft;
    const scroll = (x - this.startX) * 0.5;
    slider.scrollLeft = this.scrollLeft - scroll;
  }

  onScroll(event: any) {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;

    if (scrollTop == 0 && this.totalElement > ceil(this.displayCount / 10)) {
      this.displayCount += 10;
      this.selectionService.setDisplayCount(this.displayCount);
      this.loadMoreBefore.emit();
    }
    // if (scrollTop + offsetHeight + 10 >= scrollHeight) {
    //   console.log(
    //     'sono io il colpevole',
    //     this.totalElement,
    //     ceil(this.displayCount / 10)
    //   );
    // }
    if (
      scrollTop + offsetHeight + 10 >= scrollHeight &&
      this.totalElement > ceil(this.displayCount / 10)
    ) {
      this.displayCount += 10;
      this.selectionService.setDisplayCount(this.displayCount);
      this.loadMore.emit();
    }
  }
  calculatedHeights: { [key: string]: string } = {};

  getHeight(id: any) {
    let contentHeight = document.getElementById(id);
    let height = contentHeight?.getBoundingClientRect().height;

    return height ? height + 'px' : '50px';
  }

  scrollToIndex(index: number): void {
    if (index <= 0) {
      // Se l'indice è 0 o negativo, scrollo fino all'inizio della tabella
      this.scrollableDiv.nativeElement.scrollTop = 0;
    } else {
      let targetIndex = index + 1;
      let indexContent = this.data.findIndex(
        (res: any) => res.indice === targetIndex
      );
      this.displayCount = indexContent + 2;
      this.selectionService.setDisplayCount(this.displayCount);
      // Riduci l'indice di 1 per corrispondere all'indice dell'array
      setTimeout(() => {
        let myContent = document.getElementById(
          this.id + '-table-content-' + targetIndex
        );
        if (myContent) {
          myContent.scrollIntoView();
          myContent.style.background = 'rgba(242, 204, 5, 0.1)';
        } else {
          console.log('Errore: elemento non trovato');
        }
      }, 0);
    }
  }
}

//Ps Vince pure secondo Carmelo il def sta per deficente quindi poi rinominala :)
