// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  stage: '',
};

export const api = {
  // baseUrl: 'http://192.168.1.58:3000/',
  baseUrl: 'https://api-mida.adpl.it/',
  // baseUrl: 'http://192.168.1.59:3000/',
  // baseUrl: 'http://localhost:3000/',
  prefixAuthorization: 'Bearer',
  endpoint: {
    login: 'auth/admin/login',
    active: 'auth/active',
    register: 'auth/register',
    resetPassword: 'auth/recovery-password',
    changePassword: 'auth/change-password',
    logout: 'auth/logout',
    paginate: 'admin/paginate/users',
    recovery: 'auth/recovery-password',
    personal: 'auth/change-password',
    createOperator: 'auth/admin/createOperator',
    sendEmail: 'auth/send-recovery-email',
    paginateOperator: 'admin/paginate/operators',
    deleteOperator: 'admin/operator',
    modifyOperator: 'admin/operator',
    createBando: 'consigliato',
    paginateBando: 'consigliato/paginate',
    modifyBando: 'consigliato',
    deleteBando: 'consigliato',
    createNotificacion: 'notifica',
    Abbonamento: '/abbonamento',
    Ente: 'ente',
    Materia: 'materia/list',
    Profilo: 'profilo',
    Risposta: 'risposta',
    Domanda: 'domanda',
    createBancaDati: 'bancadati/create/form',
    regione: 'regione',
    paginateBancaDati: 'bancadati/prova',
    importaCreateBancaDati: 'bancadati/create/import',
    deleteBancaDati: 'bancadati',
    deleteBancheDati: 'bancadati/bulk',
    exportBancaDati: 'bancadati/exportXLSX',
    getBancaDati: 'bancadati/details',
    duplicateBancaDati: 'bancadati/duplicate',
    modifyBancaDatiNome: 'bancadati/name',
    modifyBancaDatiDescrizione: 'bancadati/description',
    modifyBancaDatiEnte: 'bancadati/ente',
    aggiungiDomanda: 'bancadati/add',
    modifyBanca: 'bancadati/update',
    exportDetails: 'bancadati/exportXLSX/domande',
    getLib: 'bancadati/lib/xlsx',
    banca: 'bancadati',
    exist: 'bancadati/exist',
    createFromLib: 'bancadati/create/fromLib',
    importSingola: 'bancadati/import/domande',
    importSingolaFromLib: 'bancadati/import/domande/fromLib',
    modificaDati: 'admin/profile',
    duplicateBancaDatiDomanda: 'bancadati/domanda/duplicate',
    deleteDomanda: 'bancadati/domanda/remove',
    getDomande: 'domanda/all',
    materia: 'materia/list',
    refreshToken: 'auth/refresh',
    modifyDomanda: 'domanda/update',
    importaQuiz: 'domanda/import/fromFile',
    exportQuiz: 'domanda/exportXLSX',
    deleteDomandaBulk: 'domanda/delete',
    duplicateDomanda: 'domanda/duplicate',
    createBancaDatiFromQuiz: 'bancadati/create/fromQuiz',
    getArgomento: 'argomento/all',
    getCover: 'bancadati/lib/cover',
    addDomanda: 'domanda/add',
    publishBancaDati: 'bancadati/publish',
    unpublishBancaDati: 'bancadati/unpublish',
    perTe: 'bancadati/per_te',
    utentiAttivi: 'CMS/admin/user/active',
    anno: 'anno',
    titoloDiStudio: 'titolo-studio',
    entePaginate: 'ente/paginate',
    modEnte: 'ente/nome',
    profiloPaginate: 'profilo/paginate',
    modProfilo: 'profilo/nome',
    annoPaginate: 'anno/paginate',
    abbonamento: 'abbonamento',
    getabb: 'tipo-abbonamento',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
