import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { MappingService } from '@services/mapping.service';
import { StatoUtenteService } from '@services/stato-utente.service';
import { Breadcrumb } from '@stdoutsrl/ui-kit';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  showHamburger: boolean = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private mappingService: MappingService,
    private statoUtente: StatoUtenteService
  ) {}
  isLoggin: boolean = false;
  url: string = '';

  ngOnInit() {
    this.url = this.router.url;
    if (this.url.includes('/atterraggio')) {
      this.statoUtente.setIsLoggin(true);
    }
    this.statoUtente.userInfo().subscribe();
    this.statoUtente.getIsLoggin().subscribe((res) => {
      this.isLoggin = res;
    });

    if (window.innerWidth < 800) {
      this.showHamburger = true;
    } else {
      this.showHamburger = false;
    }
  }

  onResize(event: any) {
    if (event.target.innerWidth < 800) {
      this.showHamburger = true;
    } else {
      this.showHamburger = false;
    }
  }
}
