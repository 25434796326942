<div class="h-full w-full overflow-visible " [ngClass]="{'table-shadow': !overstack}">
    <div class="w-full h-full flex rounded-xl overflow-visible ">

        <div *ngIf="showCheckbox" class="h-full w-[58px] mr-2.5" [ngClass]="{'table-shadow': !overstack}">
            <div [class]="'w-[58px] h-[58px] flex items-center justify-center bg-[#0B0E42] rounded-t-[12px] ' + checkboxHeaderClass"
                [ngClass]="{'mb-2.5 rounded-b-[12px] table-shadow': overstack}" (click)="checkAll($event)">
                <stdout-checkbox [checked]="(check$ | async) || false">

                </stdout-checkbox>
            </div>

            <div (scroll)="scrollCheckbox($event)" [id]="id + '-checkbox-container'"
                [class]="'bg-white h-[calc(100%-58px)] overflow-y-scroll rounded-b-[12px] ' + checkboxContainerClass"
                [ngClass]="{'table-shadow rounded-t-[12px]': overstack,'h-auto': data.length<10}">
                <div [style]="'height:'+getHeight(id + '-table-content-' + item.indice)"
                    *ngFor="let item of data,let index = index" [class]="'w-[58px] flex items-center justify-center'">
                    <stdout-checkbox [class]="checkboxBody" [value]="item.selected || myCheck.includes(item.id)"
                        (change)="onCheckboxChange(item, $event)" [id]="item.id">
                    </stdout-checkbox>
                </div>
            </div>

        </div>




        <div class="w-full" [ngClass]="{'!w-[calc(100%-58px)]': showCheckbox}">
            <div [id]="id + '-table-header'" (scroll)="scrollHeader($event)"
                [class]="'w-full flex items-center rounded-t-[12px] overflow-x-scroll bg-[#0B0E42] h-[58px] overflow-y-hidden' + headerClass"
                [ngClass]="{'rounded-b-[12px] mb-2.5 table-shadow':overstack}">

                <div *ngFor="let title of titles,let index = index,let last = last,let first = first"
                    [class]="'text-center w-full p-[16px] bg-[#0B0E42] text-[16px] text-white uppercase min-w-[250px] ' + headerClass"
                    [ngClass]="{}">
                    <div [id]="id +'-title-table-index-'+index">
                        <p [class]="'break-words max-w-full '+ headerText">{{title.title}}</p>
                    </div>
                </div>
            </div>
            <div [id]="id + '-table-content'" (scroll)="scrollContent($event)"
                (mousedown)="isScrollable ? startDragging($event, scrollableDiv) : null"
                (mouseup)="isScrollable ? stopDragging() : null" (mouseleave)="isScrollable ? stopDragging() : null"
                (mousemove)="isScrollable ? moveEvent($event, scrollableDiv) : null" #scrollableDiv
                [class]="'bg-white w-full h-[calc(100%-58px)] scrollable-table overflow-y-scroll rounded-b-[12px] ' + tableContainerClass"
                [ngClass]="{'table-shadow rounded-t-[12px]': overstack,'h-auto': data.length<10}">
                <div [id]="id + '-table-content-' + item.indice "
                    *ngFor="let item of data.slice(0,displayCount),let indexTable = index,let lastTable = last,let firstTable = first"
                    class="flex items-center">
                    <div class="h-full w-full flex">
                        <div (dblclick)="clickedItem.emit(item)"
                            class="w-full h-auto text-center p-[28px] min-w-[250px]"
                            [ngClass]="{'border-b-[1px] border-b-[rgba(11, 14, 66, 0.1)]':!lastTable,'border-r-[1px] border-r-[rgba(11, 14, 66, 0.1)]':!last}"
                            [id]="id + '-table-content-' + item.indice + '-' + index"
                            *ngFor="let title of titles,let index = index,let first = first,let last = last">
                            <div class="h-full w-full flex items-center place-content-center">
                                <p [class]="'break-words max-w-full '+tabText"> {{item[title.value]}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>