import { Injectable } from '@angular/core';
import { BanchedatiService } from './banchedati.service';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';

@Injectable({
  providedIn: 'root',
})
export class MappingService {
  constructor(private banchedati: BanchedatiService) {}

  private filter = new BehaviorSubject<string>('');

  private listaEnti = new BehaviorSubject<any[]>([]);
  private listaEntiQuiz = new BehaviorSubject<any[]>([]);
  private nomiEnti = new BehaviorSubject<any[]>([]);

  setFilter(value: string) {
    this.filter.next(value);
  }

  // sezione ente

  getListaEnti() {
    return this.listaEnti.asObservable();
  }

  getNomiEnti() {
    return this.listaEnti
      .asObservable()
      .pipe(map((enti) => enti.map((item) => item.nome)));
  }

  getListaEntiQuiz() {
    return this.listaEntiQuiz.asObservable();
  }

  getNomiEntiQuiz() {
    return this.listaEntiQuiz
      .asObservable()
      .pipe(map((enti) => enti.map((item) => item.nome)));
  }

  setListaEnti(array: any[]) {
    this.listaEnti.next(array);
  }

  setListaEntiQuiz(array: any[]) {
    this.listaEntiQuiz.next(array);
  }

  getEnte(): Observable<any[]> {
    return this.banchedati.getEnte(this.filter.value).pipe(
      map((response: any) => {
        let enti = response.map((response: any) => ({
          id: response.id,
          nome: response.nome === '' ? 'ente vuoto' : response.nome,
        }));

        enti.unshift({
          id: null,
          nome: '',
        });

        return enti;
      }),
      tap((enti: any[]) => {
        this.setListaEnti(enti);
      })
    );
  }

  getEnteQuiz(): Observable<any[]> {
    return this.banchedati.getEnteQuiz(this.filter.value).pipe(
      map((response: any) => {
        let enti = response.map((response: any) => ({
          id: response.id,
          nome: response.nome === '' ? 'ente vuoto' : response.nome,
        }));

        enti.unshift({
          id: null,
          nome: '',
        });

        return enti;
      }),
      tap((enti: any[]) => {
        this.setListaEntiQuiz(enti);
      })
    );
  }

  // sezione profilo

  private listaProfili = new BehaviorSubject<any[]>([]);
  private nomiProfili = new BehaviorSubject<any[]>([]);
  private enteID = new BehaviorSubject<number>(0);

  setEnteID(id: number) {
    this.enteID.next(id);
  }

  getListaProfili() {
    return this.listaProfili.asObservable();
  }

  getNomiProfili() {
    return this.listaProfili
      .asObservable()
      .pipe(map((profili) => profili.map((item) => item.nome)));
  }

  setListaProfili(array: any[]) {
    this.listaProfili.next(array);
  }

  getProfili() {
    return this.banchedati
      .getProfilo(this.enteID.value, this.filter.value)
      .pipe(
        map((response: any) => {
          const listaProfili = response
            .map((profilo: any) => {
              return {
                id: profilo.id,
                nome:
                  profilo.nome === null || profilo.nome === '-'
                    ? 'non disponibile'
                    : profilo.nome,
              };
            })
            .filter((profilo: any) => profilo !== null);
          listaProfili.unshift({ id: null, nome: '' });
          // listaProfili.push({ id: -1, nome: 'profilo vuoto' });
          this.setListaProfili(listaProfili);

          const nomiProfili = listaProfili.map((item: any) => item.nome);
          this.nomiProfili.next(nomiProfili);

          return this.getNomiProfili();
        })
      );
  }

  getProfiliQuiz() {
    return this.banchedati
      .getProfiloQuiz(this.enteID.value, this.filter.value)
      .pipe(
        map((response: any) => {
          const listaProfili = response
            .map((profilo: any) => {
              return {
                id: profilo.id,
                nome:
                  profilo.nome === null || profilo.nome === '-'
                    ? 'non disponibile'
                    : profilo.nome,
              };
            })
            .filter((profilo: any) => profilo !== null);
          listaProfili.unshift({ id: null, nome: '' });
          // listaProfili.push({ id: -1, nome: 'profilo vuoto' });
          this.setListaProfili(listaProfili);

          const nomiProfili = listaProfili.map((item: any) => item.nome);
          this.nomiProfili.next(nomiProfili);

          return this.getNomiProfili();
        })
      );
  }

  // sezione regione

  private listaRegioni = new BehaviorSubject<any[]>([]);
  private nomiRegioni = new BehaviorSubject<any[]>([]);

  getListaRegioni() {
    return this.listaRegioni.asObservable();
  }

  getNomiRegioni() {
    return this.listaRegioni
      .asObservable()
      .pipe(map((regioni) => regioni.map((item) => item.nome)));
  }

  setListaRegioni(array: any[]) {
    this.listaRegioni.next(array);
  }

  getRegioni() {
    return this.banchedati.getRegione().pipe(
      map((response: any) => {
        const listaRegioni = response.map((regione: any) => ({
          id: regione.id,
          nome: regione.nome,
        }));
        listaRegioni.unshift({ id: null, nome: '' });
        this.setListaRegioni(listaRegioni);

        const nomiRegioni = listaRegioni.map((item: any) => item.nome);
        this.nomiRegioni.next(nomiRegioni);

        return this.getNomiRegioni();
      })
    );
  }

  // sezione librerie
  private librerie = new BehaviorSubject<any[]>([]);

  getLibrerie() {
    return this.librerie.asObservable();
  }

  setLibrerie(array: any[]) {
    this.librerie.next(array);
  }

  getLib() {
    return this.banchedati.getLib().pipe(
      map((response: any) => {
        const listaLibrerie = response.map((lib: any) => ({
          id: lib.id,
          nome: lib.nomeFileOriginale,
        }));
        listaLibrerie.unshift({ id: null, nome: '' });
        this.setLibrerie(listaLibrerie);

        return this.getLibrerie();
      })
    );
  }

  // sezione materia

  private Listamaterie = new BehaviorSubject<any[]>([]);

  getListaMaterie() {
    return this.Listamaterie.asObservable();
  }

  setListaMaterie(array: any[]) {
    this.Listamaterie.next(array);
  }

  getMateria() {
    return this.banchedati.getMateria(this.filter.value).pipe(
      map((response: any) => {
        const listaMaterie = response.map((response: any) => ({
          id: response.id,
          nome: response.nome,
        }));
        listaMaterie.unshift({ id: null, nome: '' });
        this.setListaMaterie(listaMaterie);

        return this.getListaMaterie();
      })
    );
  }

  // sezione Argomento

  private listaArgomenti = new BehaviorSubject<any[]>([]);
  private idMateria = new BehaviorSubject<number>(0);

  setMateriaID(id: number) {
    this.idMateria.next(id);
  }

  getListaArgomenti() {
    return this.listaArgomenti.asObservable();
  }

  setListaArgomenti(array: any[]) {
    this.listaArgomenti.next(array);
  }

  getArgomento() {
    return this.banchedati
      .getArgomento(this.idMateria.value, this.filter.value)
      .pipe(
        map((response: any) => {
          const listaArgomenti = response.map((response: any) => {
            return {
              id: response.id,
              nome:
                response.nome === null || response.nome === '-'
                  ? 'non disponibile'
                  : response.nome,
            };
          });

          listaArgomenti.unshift({ id: null, nome: '' });
          this.setListaArgomenti(
            listaArgomenti.filter((argomento: any) => argomento !== null)
          );

          return this.getListaArgomenti();
        })
      );
  }

  // sezione cover

  private listaCover = new BehaviorSubject<any[]>([]);

  getListaCover() {
    return this.listaCover.asObservable();
  }

  setListaCover(array: any[]) {
    this.listaCover.next(array);
  }

  getCover() {
    return this.banchedati.getCover().pipe(
      map((response: any) => {
        const listaCover = response.map((response: any) => ({
          id: response.id,
          nome: response.nomeFileOriginale,
        }));
        listaCover.unshift({ id: null, nome: '' });
        this.setListaCover(listaCover);

        return this.getListaCover();
      })
    );
  }

  // sezione anno
  private listaAnni = new BehaviorSubject<any[]>([]);

  setListaAnni(array: any[]) {
    this.listaAnni.next(array);
  }

  getListaAnni() {
    return this.listaAnni.asObservable();
  }

  getAnno() {
    return this.banchedati.getAnno(this.filter.value).pipe(
      map((response: any) => {
        const listaAnno = response.map((response: any) => ({
          // id: response.id,
          nome: response.anno,
        }));
        listaAnno.unshift({ id: null, nome: '' });
        this.setListaAnni(listaAnno);

        return this.getListaAnni();
      })
    );
  }

  // sezione titolo di studio

  private listaTitoliDiStudio = new BehaviorSubject<any[]>([]);

  setListaTitoliDiStudio(array: any[]) {
    this.listaTitoliDiStudio.next(array);
  }

  getListaTitoliDiStudio() {
    return this.listaTitoliDiStudio.asObservable();
  }

  getTitoloDiStudio() {
    return this.banchedati.getTitoloStudio(this.filter.value).pipe(
      map((response: any) => {
        const listaTitoliDiStudio = response.map((response: any) => ({
          id: response.id,
          nome: response.nome,
        }));
        listaTitoliDiStudio.unshift({ id: null, nome: '' });
        this.setListaTitoliDiStudio(listaTitoliDiStudio);

        return this.getListaTitoliDiStudio();
      })
    );
  }

  // sezione lista Banche dati

  private listaBancheDati = new BehaviorSubject<any[]>([]);

  setListaBancheDati(array: any[]) {
    this.listaBancheDati.next(array);
  }

  getListaBancheDati() {
    return this.listaBancheDati.asObservable();
  }

  getBancheDati() {
    return this.banchedati.getListaBanche(this.filter.value).pipe(
      map((response: any) => {
        const listaBancheDati = response.map((response: any) => ({
          id: response.id,
          nome: response.nome,
          descrizione: response.bando?.descrizione,
          anno: response.anno,
        }));

        this.setListaBancheDati(listaBancheDati);

        return this.getListaBancheDati();
      })
    );
  }

  private listaAbbonamenti = new BehaviorSubject<any[]>([]);

  setListaAbbonamenti(array: any[]) {
    this.listaAbbonamenti.next(array);
  }

  getListaAbbonamenti() {
    return this.listaAbbonamenti.asObservable();
  }

  getAbbonamenti() {
    return this.banchedati.getAllAbbonamenti().pipe(
      map((response: any) => {
        const listaAbbonamenti = response.map((abbonamento: any) => ({
          id: abbonamento.id,
          nome: abbonamento.nome,
        }));

        this.setListaAbbonamenti(listaAbbonamenti);

        return this.getListaAbbonamenti();
      })
    );
  }

  abbonamenti(): Observable<any> {
    return forkJoin({
      abbonamenti: this.getAbbonamenti(),
    });
  }

  banche(): Observable<any> {
    return forkJoin({
      banche: this.getBancheDati(),
    });
  }

  profilo(): Observable<any> {
    return forkJoin({
      profili: this.getProfili(),
    });
  }

  argomento(): Observable<any> {
    return forkJoin({
      argomento: this.getArgomento(),
    });
  }

  avvia(isQuiz?: boolean): Observable<any> {
    if (isQuiz) {
      return forkJoin({
        ente: this.getEnteQuiz(),
        profili: this.getProfiliQuiz(),
        regioni: this.getRegioni(),
        lib: this.getLib(),
        materia: this.getMateria(),
        argomento: this.getArgomento(),
        cover: this.getCover(),
        anno: this.getAnno(),
        titoloDiStudio: this.getTitoloDiStudio(),
      });
    } else {
      return forkJoin({
        ente: this.getEnte(),
        profili: this.getProfili(),
        regioni: this.getRegioni(),
        lib: this.getLib(),
        materia: this.getMateria(),
        argomento: this.getArgomento(),
        cover: this.getCover(),
        anno: this.getAnno(),
        titoloDiStudio: this.getTitoloDiStudio(),
      });
    }
  }
}
