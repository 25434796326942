import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { MappingService } from './mapping.service';

@Injectable({
  providedIn: 'root',
})
export class StatoUtenteService {
  private idSubject = new BehaviorSubject<number>(0);
  private nomeSubject = new BehaviorSubject<string>('');
  private cognomeSubject = new BehaviorSubject<string>('');
  private emailSubject = new BehaviorSubject<string>('');
  private refreshSubject = new BehaviorSubject<string>('');
  private isLoggin = new BehaviorSubject<boolean>(false);
  private roleId = new BehaviorSubject<number>(0);

  id$ = this.idSubject.asObservable();
  nome$ = this.nomeSubject.asObservable();
  cognome$ = this.cognomeSubject.asObservable();
  email$ = this.emailSubject.asObservable();
  refresh = '  ';

  constructor(
    private auth: AuthService,
    private mappingService: MappingService
  ) {}

  ngOninit() {
    let token = localStorage.getItem('token');
    this.refresh = localStorage.getItem('refreshToken') || '';

    if (token === null) {
    }
  }

  userInfo() {
    return this.auth.getMe().pipe(
      map((response: any) => {
        this.idSubject.next(response.id);
        this.nomeSubject.next(response.firstName);
        this.cognomeSubject.next(response.lastName);
        this.emailSubject.next(response.email);
        // this.mappingService.avvia().subscribe();
        this.isLoggin.next(true);
        this.roleId.next(response.roleId);
        return response;
      })
    );
  }

  getRoleId(): Observable<number> {
    return this.roleId.asObservable();
  }

  setIsLoggin(value: boolean) {
    this.isLoggin.next(value);
  }

  getNome(): Observable<string> {
    return this.nomeSubject.asObservable();
  }

  getCognome(): Observable<string> {
    return this.cognomeSubject.asObservable();
  }

  getIsLoggin(): Observable<boolean> {
    return this.isLoggin.asObservable();
  }
}
